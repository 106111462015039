import axios from 'axios';

// ==================================================================================== //
// ===================================== ACTIONS ====================================== //
// ==================================================================================== //

const GET_USERS = 'GET_USERS';
const DEACTIVATE_USER = 'DEACTIVATED_USER';
const ACTIVATE_USER = 'ACTIVATED_USER';
const DEACTIVATE_USER_GODMODE = 'DEACTIVATED_USER_GODMODE';
const FETCH_USER_BY_UUID = 'FETCH_USER_BY_UUID';
const INVITE_USER = 'INVITE_USER';
const UPDATE_USER = 'UPDATE_USER';
const INVITE_USER_GODMODE = 'INVITE_USER_GODMODE';

// ============================================================ //
// ======================= GET USERS ========================== //
// ============================================================ //

export function getUsers(organization_uuid) {
	return (dispatch, getState) => {
		let state = getState();

		let { users } = state.SystemsReducer;

		if (users.length) {
			// already fetched users from store
			dispatch({
				type: GET_USERS,
				payload: null,
			});
		} else {
			// need to fetch users
			const fetchUsers = axios({
				url: '/api/v1/users/by-organization/' + organization_uuid,
				method: 'GET',
			});

			dispatch({
				type: GET_USERS,
				payload: fetchUsers,
			});
		}
	};
}

// ============================================================ //
// ==================== DEACTIVATE USER ======================= //
// ============================================================ //

export function deactivateUser(user_uuid, organization_uuid = null) {
	return dispatch => {
		let deactivatedUser = axios({
			method: 'DELETE',
			url: `/api/v1/users/${user_uuid}`,
		});
		console.log('ACTION', JSON.stringify(deactivatedUser, null, 2));
		if (organization_uuid) {
			dispatch({
				type: DEACTIVATE_USER_GODMODE,
				payload: deactivatedUser,
			});
		} else {
			dispatch({
				type: DEACTIVATE_USER,
				payload: { deactivatedUser, user_uuid },
			});
		}
	};
}

export function activateUser(user_uuid) {
	return dispatch => {
		let activateUser = axios({
			method: 'POST',
			url: `/api/v1/users/activate/${user_uuid}`,
		});
		console.log('ACTION', JSON.stringify(activateUser, null, 2));
		dispatch({
			type: ACTIVATE_USER,
			payload: activateUser,
		});
	};
}

// ============================================================ //
// =================== GET USERS BY UUID ====================== //
// ============================================================ //

export function fetchUserByUUID(uuid) {
	console.log('fetching user with id:', uuid);

	return dispatch => {
		const fetchUser = axios({
			url: '/api/v1/users/by-uuid/' + uuid,
			method: 'GET',
		});

		dispatch({
			type: FETCH_USER_BY_UUID,
			payload: fetchUser,
		});
	};
}

// ============================================================ //
// ====================== INVITE USER ========================= //
// ============================================================ //

export function inviteUser(inviteUser) {
	return dispatch => {
		const usr = axios({
			url: '/api/v1/users/invite',
			method: 'POST',
			data: {
				user: inviteUser,
			},
		}).then(function(response){
			if(response.data.success){
				let savedUser = response.data.values.user;
				inviteUser.organization_id = savedUser.organization_id;
			}
		});

		return dispatch({
			type: INVITE_USER,
			payload: usr,
		});
	};
}

// ============================================================ //
// ====================== UPDATE USER ========================= //
// ============================================================ //

export function updateUser(updateUser) {
	return dispatch => {
		const usr = axios({
			url: '/api/v1/users/update',
			method: 'PUT',
			data: {
				user: updateUser,
			},
		});

		return dispatch({
			type: UPDATE_USER,
			payload: usr,
		});
	};
}

// ============================================================ //
// ================== INVITE USER GODMODE ===================== //
// ============================================================ //

export function inviteUserGodmode(inviteUser, organization_uuid) {
	// organization_uuid will only be present when a new user is being created for another organization

	return dispatch => {
		const usr = axios({
			url: '/api/v1/users/invite',
			method: 'POST',
			data: {
				user: inviteUser,
				organization_uuid,
			},
		}).then(function(response){
			if(response.data.success){
				let savedUser = response.data.values.user;
				inviteUser.organization_id = savedUser.organization_id;
			}
		});

		return dispatch({
			type: INVITE_USER_GODMODE,
			payload: usr,
		});
	};
}
