import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

/*
    This checks to see if the app is in production. If it is, we need to
    upgrade requests from http to https. This is essential for our iframed
    applications to render contents correctly.
*/

if (process.env.NODE_ENV === 'production') {
	let element = document.createElement('meta');
	element.httpEquiv = 'Content-Security-Policy';
	element.content = 'upgrade-insecure-requests';

	document.head.appendChild(element);
}

ReactDOM.render(<App />, document.getElementById('root'));
