import Swal from 'sweetalert2';

const initialState = {
	users: [],
	loadingUsers: false,
	deactivatingUser: false,
	loadingUser: false,
	invitingUser: false,
	updatingUser: false,
	organizations: [],
	organizationFields: [],
	fetchingOrganizationFields: false,
	loadingOrganizations: false,
	fetchingOrganization: false,
	packages: [],
	fetchingPackages: false,
	allApplications: [],
	package_applications: [],
	fetchingApplications: false,
	dashboardInfo: {
		dataset: [],
		email_stats_categories: [],
	},
	fetchingDashboardInfo: false,
	fetchingAccountDetails: false,

	errorMessage: '',
	saving: false,
	savingError: false,
};

// ==================================================================================== //
// ===================================== ACTIONS ====================================== //
// ==================================================================================== //

const GET_USERS = 'GET_USERS';
const ACTIVATE_USER = 'ACTIVATED_USER';
const DEACTIVATE_USER = 'DEACTIVATED_USER';
const DEACTIVATE_USER_GODMODE = 'DEACTIVATED_USER_GODMODE';
const FETCH_USER_BY_UUID = 'FETCH_USER_BY_UUID';
const INVITE_USER = 'INVITE_USER';
const UPDATE_USER = 'UPDATE_USER';
const INVITE_USER_GODMODE = 'INVITE_USER_GODMODE';

const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
const DEACTIVATE_ORGANIZATION = 'DEACTIVATE_ORGANIZATION';

const GET_PACKAGES = 'GET_PACKAGES';
const GET_All_APPS = 'GET_All_APPS';
const UPDATE_PACKAGES = 'UPDATE_PACKAGES';

const GET_ORGANIZATION_FIELDS = 'GET_ORGANIZATION_FIELDS';
const UPDATE_ORGANIZATION_FIELDS = 'UPDATE_ORGANIZATION_FIELDS';

const GET_SYSTEMS_DASHBOARD = 'GET_SYSTEMS_DASHBOARD';
const GET_SYSTEMS_USER_DASHBOARD = 'GET_SYSTEMS_USER_DASHBOARD';

const TOGGLE_CONFIRMATION = 'TOGGLE_CONFIRMATION';

// ==================================================================================== //
// ===================================== REDUCER ====================================== //
// ==================================================================================== //

export default function reducer(state = initialState, action) {
	switch (action.type) {
		// ==================================================================================== //
		// ==================================== GET USERS ===================================== //
		// ==================================================================================== //

		case GET_USERS + '_PENDING':
			return { ...state, loadingUsers: true };
		case GET_USERS + '_FULFILLED':
			return {
				...state,
				loadingUsers: false,
				users: action.payload.data.values.users,
			};
		case GET_USERS + '_REJECTED':
			return { ...state, loadingUsers: false };

		// ==================================================================================== //
		// ================================= DEACTIVATE USER ================================== //
		// ==================================================================================== //

		case DEACTIVATE_USER + '_PENDING':
			console.log('ACTION', JSON.stringify(action.payload, null, 2));
			return { ...state, deactivatingUser: true, saving: true };
		case DEACTIVATE_USER + '_FULFILLED':
			console.log('ACTION', JSON.stringify(action.payload, null, 2));
			let deActiveUsersOfOrg = state.users || [];
			console.log('deActiveUsersOfOrg', deActiveUsersOfOrg);
			deActiveUsersOfOrg.map(elem => {
				if (elem.uuid === action.payload.user_uuid) {
					elem.is_active = true;
				}
				return elem;
			});
			return { ...state, users: deActiveUsersOfOrg, deactivatingUser: false, saving: false };
		case DEACTIVATE_USER + '_REJECTED':
			console.log('ACTION', JSON.stringify(action.payload, null, 2));
			return { ...state, deactivatingUser: false, savingError: true };

		// ==================================================================================== //
		// ================================= ACTIVATE USER ================================== //
		// ==================================================================================== //

		case ACTIVATE_USER + '_PENDING':
			// console.log('ACTION_PENDING', JSON.stringify(action.payload, null, 2));
			return { ...state, deactivatingUser: true, saving: true };
		case ACTIVATE_USER + '_FULFILLED':
			// console.log('ACTION_FULFILLED', JSON.stringify(action.payload, null, 2));
			console.log(action.payload, 'payload');
			let usersOfOrg = state.users || [];
			console.log('usersOfOrg', usersOfOrg);
			usersOfOrg.map(elem => {
				if (elem.uuid === action.payload.user_uuid) {
					elem.is_active = true;
				}
				return elem;
			});
			let activate_organization_users = state.organizations.map(org => {
				if (org.id === action.payload.data.values.organization_id) {
					org.users.map(user => {
						if (user.uuid === action.payload.data.values.user_uuid) {
							user.is_active = true;
						}
						return user;
					});
					// org.users = org.users.filter(user => user.uuid !== deactivate_user_uuid);
				}
				return org;
			});
			Swal.fire({
				title: 'Activated!',
				text: 'User is Activated',
				icon: 'success',
				showConfirmButton:  true,
			});
			return {
				...state,
				organizations: activate_organization_users,
				deactivatingUser: false,
				saving: false,
			};
		case ACTIVATE_USER + '_REJECTED':
			Swal.fire({
				title: 'Error!',
				text: action.payload.response.data.err,
				icon: 'error',
				showConfirmButton:  true,
			});
			console.log('ACTION_REJECTED', JSON.stringify(action.payload, null, 2));
			return { ...state, deactivatingUser: false, savingError: true };

		// ==================================================================================== //
		// ================================= DEACTIVATE USER ================================== //
		// ==================================================================================== //

		case DEACTIVATE_USER_GODMODE + '_PENDING':
			return { ...state, deactivatingUser: true, saving: true };
		case DEACTIVATE_USER_GODMODE + '_FULFILLED':
			let {
				user_uuid: deactivate_user_uuid,
				organization_id: deactivate_organization_id,
			} = action.payload.data.values;

			let deactivate_organization_users = state.organizations.map(org => {
				if (org.id === deactivate_organization_id) {
					org.users.map(user => {
						if (user.uuid === deactivate_user_uuid) {
							user.is_active = false;
						}
						return user;
					});
					// org.users = org.users.filter(user => user.uuid !== deactivate_user_uuid);
				}
				return org;
			});
			Swal.fire({
				title: 'Deactivated!',
				text: 'User is Deactivated',
				icon: 'success',
				showConfirmButton:  true,
			});
			return {
				...state,
				organizations: deactivate_organization_users,
				deactivatingUser: false,
				saving: false,
			};
		case DEACTIVATE_USER_GODMODE + '_REJECTED':
			Swal.fire({
				title: 'Error!',
				text: action.payload.response.data.err,
				icon: 'error',
				showConfirmButton:  true,
			});
			return { ...state, deactivatingUser: false, savingError: true };

		// ==================================================================================== //
		// ==================================== FETCH USER ==================================== //
		// ==================================================================================== //

		case FETCH_USER_BY_UUID + '_PENDING':
			return { ...state, loadingUser: true };
		case FETCH_USER_BY_UUID + '_FULFILLED':
			// check if user already exists in users object. if yes, do nothing. if no, push it.
			let fetchUsers = state.users;
			let fetchUser = fetchUsers.filter(
				usr => usr.uuid === action.payload.data.values.user.uuid,
			);

			// user does not exist in users array
			if (!fetchUser.length) {
				fetchUsers.push(action.payload.data.values.user);
			}

			return { ...state, users: fetchUsers, loadingUser: false };
		case FETCH_USER_BY_UUID + '_REJECTED':
			return { ...state, loadingUser: false };

		// ==================================================================================== //
		// =================================== INVITE USER ==================================== //
		// ==================================================================================== //

		case INVITE_USER + '_PENDING':
			return { ...state, invitingUser: true, saving: true };
		case INVITE_USER + '_FULFILLED':
			let users = state.users;

			users.push(action.payload.data.values.user);

			Swal.fire({
				title: 'Alert',
				text: 'Successfully invited new user!',
				icon: 'info',
				showConfirmButton: true,
			});
			
			// todo add custom message.
			return { ...state, invitingUser: false, users, saving: false };
		case INVITE_USER + '_REJECTED':
			let errorMessage = action.payload.response.data.err;
			
			
			Swal.fire({
				title: 'Alert',
				text:errorMessage ,
				icon: 'info',
				showConfirmButton:  true,
			});
			return { ...state, invitingUser: false, errorMessage, savingError: true };

		// ==================================================================================== //
		// =================================== UPDATE USER ==================================== //
		// ==================================================================================== //

		case UPDATE_USER + '_PENDING':
			return { ...state, updatingUser: true, saving: true };
		case UPDATE_USER + '_FULFILLED':
			// replace redux store user with updated user
			let updateUsers = state.users.map(usr =>
				usr.uuid === action.payload.data.values.user.uuid
					? action.payload.data.values.user
					: usr,
			);
			return { ...state, updatingUser: false, users: updateUsers, saving: false };
		case UPDATE_USER + '_REJECTED':
			return { ...state, updatingUser: false, savingError: true };

		// ==================================================================================== //
		// =============================== INVITE USER GODMODE ================================ //
		// ==================================================================================== //

		case INVITE_USER_GODMODE + '_PENDING':
			return { ...state, invitingUser: true };
		case INVITE_USER_GODMODE + '_FULFILLED':
			
			Swal.fire({
				title: 'Alert',
				text: 'Successfully invited new user!',
				icon: 'info',
				showConfirmButton:  true,
			});
			return { ...state, invitingUser: false };
		case INVITE_USER_GODMODE + '_REJECTED':
			let gmErrorMessage = action.payload.response.data.err;
			return {
				...state,
				invitingUser: false,
				errorMessage: gmErrorMessage,
				savingError: true,
			};

		// ==================================================================================== //
		// ================================ GET ORGANIZATIONS ================================= //
		// ==================================================================================== //

		case GET_ORGANIZATIONS + '_PENDING':
			return { ...state, loadingOrganizations: true };
		case GET_ORGANIZATIONS + '_FULFILLED':
			return {
				...state,
				loadingOrganizations: false,
				organizations: action.payload.data.values.organizations,
			};
		case GET_ORGANIZATIONS + '_REJECTED':
			return { ...state, loadingOrganizations: false };

		// ==================================================================================== //
		// ================================ FETCH ORGANIZATION ================================ //
		// ==================================================================================== //

		case FETCH_ORGANIZATION + '_PENDING':
			return { ...state, fetchingOrganization: true };
		case FETCH_ORGANIZATION + '_FULFILLED':
			let { organizations } = state;
			let { organization } = action.payload.data.values;

			organizations = organizations.map(org => {
				if (org.uuid === organization.uuid) {
					organization.hasFetchedDetails = true;
					return { ...org, ...organization };
				}
				return org;
			});

			return {
				...state,
				fetchingOrganization: false,
				organizations,
			};
		case FETCH_ORGANIZATION + '_REJECTED':
			return { ...state, fetchingOrganization: false };

		// ==================================================================================== //
		// ================================ UPDATE ORGANIZATION =============================== //
		// ==================================================================================== //

		case UPDATE_ORGANIZATION + '_PENDING':
			return { ...state, updatingOrganization: true, saving: true };
		case UPDATE_ORGANIZATION + '_FULFILLED':
			let { organizations: update_organizations } = state;
			let update_organization = action.payload.data.values.organization;

			update_organizations = update_organizations.map(org => {
				if (org.uuid === update_organization.uuid) {
					update_organization.hasFetchedDetails = true;
					return { ...org, ...update_organization };
				}
				return org;
			});

			return {
				...state,
				updatingOrganization: false,
				organizations: update_organizations,
				saving: false,
			};
		case UPDATE_ORGANIZATION + '_REJECTED':
			return { ...state, updatingOrganization: false, savingError: true };

		// ==================================================================================== //
		// ================================ UPDATE ORGANIZATION =============================== //
		// ==================================================================================== //

		case CREATE_ORGANIZATION + '_PENDING':
			return { ...state, creatingOrganization: true, saving: true };
		case CREATE_ORGANIZATION + '_FULFILLED':
			let { organizations: create_organizations } = state;
			let create_organization = action.payload.data.values.organization;

			create_organizations.push(create_organization);

			return {
				...state,
				creatingOrganization: false,
				organizations: create_organizations,
				saving: false,
			};
		case CREATE_ORGANIZATION + '_REJECTED':
			return { ...state, creatingOrganization: false, savingError: true };

		// ==================================================================================== //
		// ============================= DEACTIVATE ORGANIZATION ============================== //
		// ==================================================================================== //

		case DEACTIVATE_ORGANIZATION + '_PENDING':
			return { ...state, deactivatingOrganization: true, saving: true };
		case DEACTIVATE_ORGANIZATION + '_FULFILLED':
			
			Swal.fire({
				title: 'Alert',
				text: 'Organization has been deactivated!',
				icon: 'info',
				showConfirmButton: true,
			});
			let { organizations: deactivate_organizations } = state;
			let deactivate_organization = action.payload.data.values.organization;

			deactivate_organizations = deactivate_organizations.filter(
				org => org.uuid === deactivate_organization.uuid,
			);

			return {
				...state,
				deactivatingOrganization: false,
				organizations: deactivate_organizations,
				saving: false,
			};
		case DEACTIVATE_ORGANIZATION + '_REJECTED':
			return { ...state, deactivatingOrganization: false, savingError: true };
		// ==================================================================================== //
		// ============================= GET PACKAGES ========================================= //
		// ==================================================================================== //
		case GET_PACKAGES + '_PENDING':
			return { ...state, fetchingPackages: true };
		case GET_PACKAGES + '_FULFILLED':
			return { ...state, packages: action.payload.data.values, fetchingPackages: false };
		case GET_PACKAGES + '_REJECTED':
			return { ...state, fetchingPackages: false };
		// ==================================================================================== //
		// ============================= GET ALL APPS ========================================= //
		// ==================================================================================== //
		case GET_All_APPS + '_PENDING':
			return { ...state, fetchingApplications: true };
		case GET_All_APPS + '_FULFILLED':
			return {
				...state,
				allApplications: action.payload.data.values.applications,
				package_applications: action.payload.data.values.package_applications,
				fetchingApplications: false,
			};
		case GET_All_APPS + '_REJECTED':
			return { ...state, fetchingApplications: false };
		// ==================================================================================== //
		// ============================= UPDATE PACKAGES  ===================================== //
		// ==================================================================================== //
		case UPDATE_PACKAGES + '_PENDING':
			return { ...state, fetchingPackages: true, saving: true };
		case UPDATE_PACKAGES + '_FULFILLED':
			return {
				...state,
				packages: action.payload.data.values.packages,
				fetchingPackages: false,
				saving: false,
			};
		case UPDATE_PACKAGES + '_REJECTED':
			return { ...state, fetchingPackages: false, savingError: true };
		// ==================================================================================== //
		// ============================= UPDATE ORG FIELDS  ===================================== //
		// ==================================================================================== //
		case UPDATE_ORGANIZATION_FIELDS + '_PENDING':
			return { ...state, saving: true };
		case UPDATE_ORGANIZATION_FIELDS + '_FULFILLED':
			return {
				...state,
				organizationFields: action.payload.data.values,
				saving: false,
			};
		case UPDATE_ORGANIZATION_FIELDS + '_REJECTED':
			return { ...state, savingError: true };
		// ==================================================================================== //
		// ============================= GET ORG FIELDS ======================================= //
		// ==================================================================================== //
		case GET_ORGANIZATION_FIELDS + '_PENDING':
			return { ...state, fetchingOrganizationFields: true };
		case GET_ORGANIZATION_FIELDS + '_FULFILLED':
			return {
				...state,
				organizationFields: action.payload.data.values,
				fetchingOrganizationFields: false,
			};
		case GET_ORGANIZATION_FIELDS + '_REJECTED':
			return { ...state, fetchingOrganizationFields: false };
		// ==================================================================================== //
		// ============================= TOGGLE LOADING SCREEN ================================ //
		// ==================================================================================== //
		case TOGGLE_CONFIRMATION:
			const { saving, savingError } = action.payload;
			return { ...state, saving, savingError };
		// ==================================================================================== //
		// ============================= GET SYSTEMS DAHSBOARD ================================ //
		// ==================================================================================== //
		case GET_SYSTEMS_DASHBOARD + '_PENDING':
			return { ...state, fetchingDashboardInfo: true };
		case GET_SYSTEMS_DASHBOARD + '_FULFILLED':
			const {
				campCountRes,
				contactCountRes,
				orgCountRes,
				dataset,
				email_stats_categories,
			} = action.payload.data.values;
			return {
				...state,
				dashboardInfo: {
					campCountRes,
					contactCountRes,
					orgCountRes,
					dataset,
					email_stats_categories,
				},
				fetchingDashboardInfo: false,
			};
		case GET_SYSTEMS_USER_DASHBOARD + '_REJECTED':
			return { ...state, fetchingDashboardInfo: false };
		case GET_SYSTEMS_USER_DASHBOARD + '_PENDING':
			return { ...state, fetchingDashboardInfo: true };
		case GET_SYSTEMS_USER_DASHBOARD + '_FULFILLED':
			const {
				communication_credits,
				org_users_count,
				org_packages_count,
				org_user_activity_dataset,
				org_user_activity_categories,
			} = action.payload.data.values;
			return {
				...state,
				dashboardInfo: {
					communication_credits,
					org_users_count,
					org_packages_count,
					org_user_activity_dataset,
					org_user_activity_categories,
				},
				fetchingDashboardInfo: false,
			};
		case GET_SYSTEMS_DASHBOARD + '_REJECTED':
			return { ...state, fetchingDashboardInfo: false };
		// ==================================================================================== //
		// ===================================== DEFAULT ====================================== //
		// ==================================================================================== //

		default:
			return state;
	}
}

// ==================================================================================== //
// ================================= EXPORT METHODS =================================== //
// ==================================================================================== //
export {
	getUsers,
	deactivateUser,
	activateUser,
	fetchUserByUUID,
	inviteUser,
	updateUser,
	inviteUserGodmode,
} from './actions/systems_users_actions';

export {
	getOrganizations,
	fetchOrganization,
	updateOrganization,
	createOrganization,
	deactivateOrganization,
	getOrganizationFields,
	updateOrganizationFields,
} from './actions/systems_organizations_actions';

export { getPackages, getAllApps, updatePackages } from './actions/systems_packages_actions';
export { getSystemsDashInfo, getSystemsUserDashInfo } from './actions/systems_dashboard_actions';

// ==================================================================================== //
// =================================TOGGLE CONFIRMATION================================ //
// ==================================================================================== //
export function toggleConfirmation(saving, savingError) {
	return {
		type: TOGGLE_CONFIRMATION,
		payload: {
			saving,
			savingError,
		},
	};
}
