import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from './utils/history';
import './styles/main.css';

// ========== VIEWS ========== //
import {
	LazyCRM,
	LazyBot,
	LazySystem,
	LazyBuilder,
	LazyAccountSettings,
	LazyCuration,
	LazyScheduling,
	LazyAnalytics,
	LazyEmailCopy,
	LazyWebinars,
	LazyVideosales,
	LazyZapier
} from './LazyRoute';

import SideBar from './components/SideBar';
import NavBar from './components/NavBar';
import Header from './components/Header';

import store from './ducks/store';
import AuthWrapper, { AuthCallback } from './components/AuthWrapper';

class Main extends React.Component {
	render() {
		if (this.props.authenticated && this.props.location.pathname === '/') {
			this.props.history.push('/crm');
		}

		// this value comes from the parent AuthWrapper. Through this variable, we will be able to specify what routes are assessible
		const { authenticated, login, logout } = this.props;

		return (
			<main className="App">
				<SideBar />
				<div id="app-wrapper" className="wrapper">
					<NavBar />

					<div id="custom-content-page" className="content-page">
						<Header
							login={login}
							logout={logout}
							authenticated={authenticated}
							history={history}
						/>

						{/* CONTENT BODY */}
						<div id="custom-container-fluid" className="container-fluid h-100">
							{/*
                                ===== APPLICATION ROUTING =====

                                @NOTE each application index file will contain routing for that specific application.
                                This only routes to each app.
                            */}
							<Switch>
								<Route path="/crm" component={LazyCRM} />
								<Route path="/system" component={LazySystem} />
								<Route path="/builder" component={LazyBuilder} />
								<Route path="/curation" component={LazyCuration} />
								<Route path="/scheduling" component={LazyScheduling} />
								<Route path="/analytics" component={LazyAnalytics} />
								<Route path="/emailcopy" component={LazyEmailCopy} />
								<Route path="/settings" component={LazyAccountSettings} />
								<Route path="/webinars/" component={LazyWebinars} />
								<Route path="/videosales" component={LazyVideosales} />
								<Route path="/callback" component={AuthCallback} />
								<Route path="/bot" component={LazyBot} />
								<Route path="/zapier" component={LazyZapier} />
							</Switch>
						</div>
					</div>
				</div>
			</main>
		);
	}
}

class App extends React.Component {
	render() {
		return (
			<Router history={history}>
				<Provider store={store}>
					<AuthWrapper
						render={props => {
							return <Main {...props} />;
						}}
					/>
				</Provider>
			</Router>
		);
	}
}

export default App;
