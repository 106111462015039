import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

/**
 * @todo add a standard way to render the components
 *
 * @param tabsData Object->{ handler, tabs }
 * 	@param handler tabsDataFunction->use this to set state with Fn response. Response will be label from your array for currently active tab;
 * 	@param tabs ArrayOfObjects->[{label: 'tab1', icon: 'account'}, {label: 'tab2', icon: 'settings'}, {label: 'tab3', icon: 'account'}] Array of Strings;
 *
 * @link https://materialdesignicons.com/
 * 	Use title of icon in a string as the value for each tabs object icon value
 * 
 * @description
 * 	Icons: Dynamically adding mdi icons. Find the Icon you wish to use, and pass it in as the icon property value as a string for each tab.
 * 	A component to dynamically create menu tabs and returns the value of the activeTab from the labels that you pass in. Developer sets the different conditions to render which component on which tab label
 *
 * @example (handler)
 * 	handleTabs = (tab) => this.setState({ activeTab: tab});
 *
 * @example (Prop)
 *	<Tabs tabsData = {{ handler: this.handleTabs, tabs: [{label: 'tab1', icon: 'account'}, {label: 'tab2', icon: 'settings'}, {label: 'tab3', icon: 'account'}] }}
 *
 * ---------------------------
 *
 *	render(
 * 	const { activeTab } = this.state;
 * 	return {
 *
			<React.Fragment>
				<Card>
				<Tabs tabs = {{handler: this.tabHandler, tabs: ['Contact Details', 'Automation', 'Activity Log']}}/>
						{
							activeTab === 'Contact Details' ? <ContactDetails /> :
							activeTab === 'Automation' ? <ContactAutomation /> :
							activeTab === 'Activity Log' ? <ContactActivityLog /> : null
						}
				</Card>
				</React.Fragment>
		})
 */

//|||||||||||| TABS ||||||||||||\\

class Tabs extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTab: this.props.tabsData.tabs[0].label,
		};
	}
	componentDidMount() {
		return this.props.tabsData.handler(this.props.tabsData.tabs[0].label);
	}
	onClickTabItem = tab => {
		this.setState({ activeTab: tab }, () => {
			this.props.tabsData.handler(tab);
		});
	};

	render() {
		return (
			<React.Fragment>
				<ul className="container-fluid nav-pills nav nav-tabs nav-justified p-0">
					{this.props.tabsData.tabs.map((label, i) => {
						return (
							<Tab
								icon={label.icon}
								activeTab={this.state.activeTab}
								key={label.label}
								label={label.label}
								onClick={this.onClickTabItem}
								disabled={label.disabled}
							/>
						);
					})}
				</ul>
				<i
					// you may choose what happens when you click on the back button with a provided function
					// if not it will invoke goBack
					onClick={this.props.handleBackbutton || this.props.history.goBack}
					role={'button'}
					className={`mdi mdi-chevron-left back_button`}
				/>
			</React.Fragment>
		);
	}
}

Tabs.propTypes = {
	tabsData: PropTypes.shape({
		handler: PropTypes.func.isRequired,
		tabs: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default withRouter(Tabs);

// ||||||||||||| INDIVIDUAL TAB ||||||||||||||||

class Tab extends React.Component {
	tabClick = () => {
		const { label, onClick } = this.props;
		onClick(label);
	};

	render() {
		const {
			props: { activeTab, label, disabled },
		} = this;
		return (
			<li
				onClick={!disabled ? this.tabClick : () => {}}
				className="nav-item"
				id={disabled ? 'custom-tab-disable' : ''}
			>
				<div
					className={`nav-link p-1x ${activeTab === label ? 'active' : ''}`}
					id={disabled ? 'custom-tab-disable' : ''}
				>
					<i
						className={`mdi p-1x mdi-${this.props.icon} mdi-1x d-lg-none d-block mr-1`}
					/>
					<span className="d-none p-1 d-lg-block"> {this.props.label} </span>
				</div>
			</li>
		);
	}
}
