import { SAVE_QUESTIONFROM6, GET_QUESTIONFROM6 } from '../actions/webinarActions/allActions';

const initialSate = {
	questionform6Array: [],
	get_questionform6Array: [],
};
const questionForm6Reducer = (state = initialSate, action) => {
	switch (action.type) {
		case SAVE_QUESTIONFROM6: {
			return {
				...state,
				questionform6Array: action.response,
			};
		}
		case GET_QUESTIONFROM6: {
			return {
				...state,
				get_questionform6Array: action.response,
			};
		}
		default:
			return state;
	}
};
export default questionForm6Reducer;
