import React from 'react';

// ========== DYNAMIC IMPORT ========== //

class DynamicImport extends React.Component {
	state = {
		component: null,
	};
	componentDidMount() {
		this.props.load().then(component => {
			this.setState({ component: component.default });
		});
	}
	render() {
		return this.props.children(this.state.component);
	}
}

//  ========== APP ROUTES ========== //

export const LazyCRM = props => (
	<DynamicImport load={() => import('./views/crm/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);

export const LazyBot = props => (
	<DynamicImport load={() => import('./views/bot/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);


export const LazySystem = props => (
	<DynamicImport load={() => import('./views/system/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);

export const LazyBuilder = props => (
	<DynamicImport load={() => import('./views/builder/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);

export const LazyCuration = props => (
	<DynamicImport load={() => import('./views/curation/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);

export const LazyScheduling = props => (
	<DynamicImport load={() => import('./views/scheduling/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);

export const LazyVideoSales = props => (
	<DynamicImport load={() => import('./views/videosales/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);

export const LazyAnalytics = props => (
	<DynamicImport load={() => import('./views/analytics/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);

export const LazyEmailCopy = props => (
	<DynamicImport load={() => import('./views/emailcopy/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);

export const LazyAccountSettings = props => (
	<DynamicImport load={() => import('./views/Settings/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);

export const LazyWebinars = props => (
	<DynamicImport load={() => import('./views/webinars/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);
export const LazyVideosales = props => (
	<DynamicImport load={() => import('./views/videosales/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);
export const LazyZapier = props => (
	<DynamicImport load={() => import('./views/zapier/index')}>
		{Component => (Component === null ? '' : <Component {...props} />)}
	</DynamicImport>
);
// ========== SUB ROUTES ========== //
