import { SAVE_TEMPLATE_PODCAST_OUTREACH, UPDATE_COMPAIGN } from '../actions/allActions';

const initialSate = {
	templateFormArray: [],
	update_response: '',
};
const podcastOutreachReducer = (state = initialSate, action) => {
	switch (action.type) {
		case SAVE_TEMPLATE_PODCAST_OUTREACH: {
			return {
				...state,
				templateFormArray: action.response,
			};
		}
		case UPDATE_COMPAIGN: {
			return {
				...state,
				update_response: action.response,
			};
		}
		default:
			return state;
	}
};
export default podcastOutreachReducer;
