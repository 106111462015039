import React from 'react';

import Card from './Card';

class LoginCard extends React.Component {
	state = {
		loader: false,
	};
	componentDidMount() {
		this.setState({
			loader: this.props.authLoader,
		});
		// this.state.loader = this.props.authLoader;
	}
	render() {
		return this.props.authenticated ? (
			<LoadingScreen
				logInModal={this.props.newCustomer}
				newCustomer={this.props.newCustomer}
				returnToLogin={this.returnToLogin}
			/>
		) : this.props.location.pathname === '/' && !this.state.loader ? (
			<LoadingScreen
				login={this.props.login}
				logInModal={true}
				returnToLogin={this.returnToLogin}
			/>
		) : (
			<LoadingScreen
				login={this.props.login}
				spinner={true}
				returnToLogin={this.returnToLogin}
			/>
		);
	}
}

export default LoginCard;
const LoadingScreen = props => {
	return (
		<React.Fragment>
			<div className="login-modal-wrapper">
				{props.logInModal && (
					<section>
						<Card>
							<div className="d-flex flex-column align-items-center">
								{props.newCustomer || (
									<React.Fragment>
										<h3>Oops! You are not logged in. </h3>
										<h3>Please log in now to continue.</h3>
									</React.Fragment>
								)}
								<br />
								{props.newCustomer || (
									<button
										className="btn btn-primary btn-lg w-25"
										onClick={props.login}
									>
										Login
									</button>
								)}
							</div>
						</Card>
					</section>
				)}
				{props.spinner && (
					<img
						src="/assets/images/infinity-spinner.gif"
						className={`infinity-spinner loading`}
						alt="loading spinner"
					/>
				)}
			</div>
			<div className="App">
				{/* sidebar */}
				<div className="SideBar">
					<div className="imgContainer">
						<div className="loading-sidebar-icons" />
					</div>
					<div className="imgContainer imgContainerActive">
						<div className="loading-sidebar-icons loading-light" />
					</div>
					<div className="imgContainer">
						<div className="loading-sidebar-icons" />
					</div>
					<div className="imgContainer">
						<div className="loading-sidebar-icons" />
					</div>
					<div className="imgContainer">
						<div className="loading-sidebar-icons" />
					</div>
					<div className="fillSideBar">
						<div to="/settings">
							<div className="imgContainer">
								<div className="loading-sidebar-icons" />
							</div>
						</div>
					</div>
				</div>
				{/* NavBar */}
				<div id="app-wrapper" className="wrapper">
					<div className="custom-navbar">
						<div className="slimscroll-menu">
							{/* AXIS LOGO */}
							<span className="pt-2 d-flex flex-column align-items-center justify-content-start">
								<img src="assets/images/logo.png" alt="" height="30" />
							</span>
							<div className="loading-routes">
								<div className="loading-item" />
								<div className="loading-item" />
								<div className="loading-item" />
								<div className="loading-item" />
								<div className="loading-item" />
								<div className="loading-item" />
							</div>
							{/* DFY LOGO */}
							<div id="navbar-dfy-logo" className="container">
								<img
									src="https://s3.us-east-2.amazonaws.com/axis-sidebar/dfy-logo.png"
									alt="Done For You"
									height="25"
								/>
							</div>
						</div>
					</div>
					{/* HEADER */}
					<div id="custom-content-page" className="content-page">
						<div
							id="custom-header"
							className="navbar-custom d-flex justify-content-end "
						>
							<span id="custom-user-profile" className=" nav-user arrow-none">
								<span
									id="custom-header-profile-img"
									className="account-user-avatar"
								>
									<div className="loading-sidebar-icons loading-light" />
								</span>
								<span>
									<span className="account-user-name">
										<div className="loading-profile-item" />
									</span>
									<span className="account-position">
										<div className="loading-profile-item" />
									</span>
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
