
  import { GET_STEPS } from '../actions/webinarActions/allActions';
  
  const initialSate = {
    stepsArray: [],
  };
  const stepsReducer = (state = initialSate, action) => {
    switch (action.type) {
      case GET_STEPS: {
        return {
          ...state,
          stepsArray: action.response,
        };
      }
      default:
        return state;
    }
  };
  export default stepsReducer;
   