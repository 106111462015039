import { SAVE_QUESTIONFROM5, GET_QUESTIONFROM5 } from '../actions/webinarActions/allActions';

const initialSate = {
	questionform3Array: [],
	get_questionform5_array: [],
};
const questionForm5Reducer = (state = initialSate, action) => {
	switch (action.type) {
		case SAVE_QUESTIONFROM5: {
			return {
				...state,
				questionform3Array: action.response,
			};
		}
		case GET_QUESTIONFROM5: {
			return {
				...state,
				get_questionform5_array: action.response,
			};
		}
		default:
			return state;
	}
};
export default questionForm5Reducer;
