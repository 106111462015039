import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import history from '../utils/history';

// ========== INITIAL STATE ========== //
const initialState = {
	analytics: {
		totalContacts: undefined,
	},
	fetchingContacts: false,
	fetchingContact: false,
	removingContacts: false,

	fetchingEmailTemplates: false,
	updatingEmailTemplate: false,
	creatingEmailTemplate: false,

	fetchingForms: false,
	fetchingFormDetails: false,
	creatingForm: false,
	updatingForm: false,
	updatingFormBuilder: false,

	fetchingCompanies: false,

	fetchingCampaigns: false,
	fetchingCampaignDetails: false,
	savingCampaignAutomation: false,
	creatingCampaign: false,
	updatingCampaign: false,

	fetchingTags: false,
	fetchingTagDetails: false,
	creatingTag: false,
	editingTag: false,

	fetchingDashboardData: false,
	fetchingEmailActivity: false,
	fetchingActivities: false,

	contacts: [],
	contact: [],
	companies: [],
	forms: [],
	emailTemplates: [],
	smsTemplates: [],
	campaigns: [],
	tags: [],
	reports: [],
	activities: [],
	dashboardData: {
		leads: [],
		formSubmissions: [],
		formsTop5: [],
		templates_top_5: [],
		emails: [],
		subscribers: { total: 0, last_month: 0 },
		total_leads: { total: 0, last_month: 0 },
		active_campaigns: { total: 0, last_month: 0 },
		emails_sent: 0,
		emails_opened: 0,
		emails_clicked: 0,
		opened_rate: 0,
		clicked_rate: 0,
	},

	saving: false,
	savingError: false,
};

// ========== ACTION TYPES ========== //
const GET_DASH_DATA = 'GET_DASH_DATA';
const GET_DASH_EMAIL_ACTIVITY = 'GET_DASH_EMAIL_ACTIVITY';
const GET_CONTACTS = 'GET_CONTACTS';
const GET_CONTACT = 'GET_CONTACT';
const CREATE_CONTACT = 'CREATE_CONTACT';
const TOGGLE_ISACTIVE = 'TOGGLE_ISACTIVE';
const UPDATE_CONTACT = 'UPDATE_CONTACT';
const IMPORT_CSV = 'IMPORT_CSV';

const GET_TEMPLATES = 'GET_TEMPLATES';
const CREATE_EMAIL_TEMPLATE = 'CREATE_EMAIL_TEMPLATE';
const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';

const CREATE_SMS = 'CREATE_SMS';
const UPDATE_SMS = 'UPDATE_SMS';

const DELETE_MESSAGE_TEMPLATE = 'DELETE_MESSAGE_TEMPLATE';

const UPLOAD_CONTACT_FILE = 'UPLOAD_CONTACT_FILE';
const DELETE_CONTACT_FILE = 'DELETE_CONTACT_FILE';

const BULK_REMOVE_CONTACTS = 'BULK_REMOVE_CONTACTS';

const UPLOAD_EMAIL_ATTACHMENT = 'UPLOAD_EMAIL_ATTACHMENT';
const DELETE_EMAIL_ATTACHMENT = 'DELETE_EMAIL_ATTACHMENT';

const GET_FORMS = 'GET_FORMS';
const GET_FORM_DETAILS = 'GET_FORM_DETAILS';
const CREATE_FORM = 'CREATE_FORM';
const UPDATE_FORM = 'UPDATE_FORM';
const UPDATE_FORM_DOCUMENTS = 'UPDATE_FORM_DOCUMENTS';
const DELETE_FORM_DOWNLOADABLES = 'DELETE_FORM_DOWNLOADABLES';
const UPDATE_FORM_BUILDER = 'UPDATE_FORM_BUILDER';
const DELETE_FORM = 'DELETE_FORM';
const UPDATE_FORM_STATUS = 'UPDATE_FORM_STATUS';

const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
const GET_CAMPAIGN_DETAILS = 'GET_CAMPAIGN_DETAILS';
const SAVE_CAMPAIGN_AUTOMATION = 'SAVE_CAMPAIGN_AUTOMATION';
const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';

const GET_TAGS = 'GET_TAGS';
const GET_TAG_DETAILS = 'GET_TAG_DETAILS';
const CREATE_TAG = 'CREATE_TAG';
const EDIT_TAG = 'EDIT_TAG';
const DELETE_TAG = 'DELETE_TAG';

const GET_COMPANIES = 'GET_COMPANIES';

const GET_ORG_ACTIVITIES = 'GET_ORG_ACTIVITIES';

const TOGGLE_CONFIRMATION = 'TOGGLE_CONFIRMATION';

const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';

// ========== REDUCER ========== //
export default function reducer(state = initialState, action) {
	switch (action.type) {
		// ==================================================================================== //
		// =================================== GET CONTACTS =================================== //
		// ==================================================================================== //

		case GET_CONTACTS + '_PENDING':
			return { ...state, fetchingContacts: true };

		case GET_CONTACTS + '_FULFILLED':
			return { ...state, contacts: action.payload.data.values, fetchingContacts: false };

		case GET_CONTACTS + '_REJECTED':
			return { ...state, fetchingContacts: false };

		// ==================================================================================== //
		// =================================== GET CONTACT ==================================== //
		// ==================================================================================== //

		case GET_CONTACT + '_PENDING':
			return { ...state, fetchingContact: true };

		case GET_CONTACT + '_FULFILLED':
			let contact = action.payload.data.values;
			return { ...state, contact, fetchingContact: false };

		case GET_CONTACT + '_REJECTED':
			return { ...state, fetchingContact: false };

		case BULK_REMOVE_CONTACTS + '_PENDING':
			return { ...state, saving: true };
		case BULK_REMOVE_CONTACTS + '_FULFILLED':
			let { contacts_removed } = action.payload.data.data.values;
			let new_contacts_set = [];
			state.contacts.forEach(contact => {
				if (!contacts_removed.includes(contact.id)) {
					new_contacts_set.push(contact);
				}
			});
			return { ...state, contacts: new_contacts_set, saving: false };
		case BULK_REMOVE_CONTACTS + '_REJECTED':
			return { ...state, saving: false };
		// ==================================================================================== //
		// ================================== DOCUMENT HANDLERS =============================== //
		// ==================================================================================== //

		case UPLOAD_CONTACT_FILE:
			var tempAttachedFiles = { ...state.contact };
			tempAttachedFiles.attached_files.push(action.payload);
			return { ...state, contact: tempAttachedFiles, saving: false };

		case DELETE_CONTACT_FILE + '_PENDING':
			return { ...state, saving: true };

		case DELETE_CONTACT_FILE + '_FULFILLED':
			const { file_key } = action.payload.data.values;

			if (action.payload.data.values.file_key) {
				var tempContact = { ...state.contact };
				tempContact.attached_files = state.contact.attached_files.filter(
					file => file.Key !== file_key,
				);
			}
			return { ...state, contact: tempContact, saving: false };

		case DELETE_CONTACT_FILE + '_REJECTED':
			return { ...state, saving: false };

		case UPLOAD_EMAIL_ATTACHMENT:
			var tempAttachedEmails = [...state.emailTemplates];
			var templateToUpdate = tempAttachedEmails.map(email => {
				if (email.uuid === action.payload.email_uuid) {
					if (!email.attachments) {
						email.attachments = [];
						email.attachments.push(action.payload);
						return email;
					} else {
						email.attachments.push(action.payload);
						return email;
					}
				} else {
					return email;
				}
			});
			return { ...state, emailTemplates: templateToUpdate, saving: false };

		case DELETE_EMAIL_ATTACHMENT + '_FULFILLED':
			const { attachment_key } = action.payload.data.values;
			let email_uuid = attachment_key.split('/')[3];
			let tempEmails = [...state.emailTemplates];
			tempEmails = tempEmails.map(email => {
				if (email.uuid === email_uuid) {
					email.attachments = email.attachments.filter(attachment => {
						return attachment.Path !== attachment_key;
					});
				}
				return email;
			});
			return { ...state, emailTemplates: tempEmails, saving: false };

		// ==================================================================================== //
		// ================================== CREATE CONTACT ================================== //
		// ==================================================================================== //

		case CREATE_CONTACT:
			let newContact = action.payload;
			let temp_contacts = [...state.contacts];
			temp_contacts.push(newContact);
			return { ...state, contacts: temp_contacts };

		// ==================================================================================== //
		// ================================== IMPORT CSV ====================================== //
		// ==================================================================================== //
		case IMPORT_CSV + '_PENDING':
			return { ...state, saving: true };
		case IMPORT_CSV + '_FULFILLED':
			const { updated, created } = action.payload.data.values;
			document.getElementById('uploadCaptureInputFile').value = '';
			let newContacts = action.payload.data.values.contacts;
			let oldIds = state.contacts.map(e => e.id);
			let newIds = newContacts.map(e => e.id);

			let refinedoldIds = oldIds
				.map((id, idx) => {
					if (!newIds.includes(id)) {
						return id;
					}
					return null;
				})
				.filter(Boolean);
			let newState = state.contacts.filter(e => {
				return refinedoldIds.includes(e.id);
			});

			let allContacts = [...newState, ...newContacts];
			if (updated > 0) {
				Swal.fire({
					title: 'Alert',
					text:
						'You have updated ' +
						updated +
						' leads and created ' +
						created +
						' leads. Nice work!',
					icon: 'info',
					showConfirmButton: true,
				});
			} else {
				Swal.fire({
					title: 'Alert',
					text: `You have added ${created} new leads. Nice work!`,
					icon: 'info',
					showConfirmButton: true,
				});
			}
			return { ...state, contacts: allContacts, saving: false };
		case IMPORT_CSV + '_REJECTED':
			document.getElementById('uploadCaptureInputFile').value = '';
			return { ...state, savingError: true };

		// ==================================================================================== //
		// ================================== TOGGLE IS ACTIVE ================================ //
		// ==================================================================================== //

		case TOGGLE_ISACTIVE + '_FULFILLED':
			const uuid = action.payload.data.data.values.uuid;
			const status = action.payload.data.data.values.is_active;

			const deactivation_date = moment(
				action.payload.data.data.values.deactivation_date,
			).format('ll');
			let contactToToggle = state.contacts.filter(e => e.uuid === uuid)[0];
			const contactIndex = state.contacts.findIndex(e => e.uuid === contactToToggle.uuid);
			contactToToggle.is_active = status;
			contactToToggle.deactivation_date = deactivation_date;
			Object.assign(state.contacts[contactIndex], contactToToggle);
			return { ...state };

		// ==================================================================================== //
		// ================================ GET TEMPLATES ===================================== //
		// ==================================================================================== //

		case GET_TEMPLATES + '_PENDING':
			return { ...state, fetchingEmailTemplates: true };

		case GET_TEMPLATES + '_FULFILLED':
			return {
				...state,
				emailTemplates: action.payload.data.values.emailTemplates,
				smsTemplates: action.payload.data.values.smsTemplates,
				fetchingEmailTemplates: false,
			};

		case GET_TEMPLATES + '_REJECTED':
			return {
				...state,
				fetchingEmailTemplates: false,
			};

		// ==================================================================================== //
		// ============================= CREATE EMAIL TEMPLATE ================================ //
		// ==================================================================================== //

		case CREATE_EMAIL_TEMPLATE + '_PENDING':
			return { ...state, creatingEmailTemplate: true, saving: true };

		case CREATE_EMAIL_TEMPLATE + '_REJECTED':
			return { ...state, creatingEmailTemplate: false, savingError: true };

		case CREATE_EMAIL_TEMPLATE + '_FULFILLED':
			let newEmailTemplate = { ...action.payload.data.values[0], message_type: 'Email' };
			let newEmailTemplatesState = [...state.emailTemplates, newEmailTemplate];
			return {
				...state,
				emailTemplates: newEmailTemplatesState,
				creatingEmailTemplate: false,
				saving: false,
			};

		// ==================================================================================== //
		// ============================= DELETE MSG TEMPLATE ================================== //
		// ==================================================================================== //

		case DELETE_MESSAGE_TEMPLATE + '_PENDING':
			return { ...state, saving: true };
		case DELETE_MESSAGE_TEMPLATE + '_FULFILLED':
			let tempArr = [];
			if (action.payload.data.values.type !== 'SMS') {
				tempArr = state.emailTemplates.filter(
					email => email.uuid !== action.payload.data.values.uuid,
				);
				return { ...state, emailTemplates: tempArr, saving: false };
			} else {
				tempArr = state.smsTemplates.filter(
					sms => sms.uuid !== action.payload.data.values.uuid,
				);
				return { ...state, smsTemplates: tempArr, saving: false };
			}
		case DELETE_MESSAGE_TEMPLATE + '_REJECTED':
			Swal.fire({
				title: 'Alert',
				text: 'Please remove this template from any active campaigns and then try again.',
				icon: 'info',
				showConfirmButton: true,
			});

			return { ...state, savingError: true };

		// ==================================================================================== //
		// ============================== UPDATE EMAIL TEMPLATE =============================== //
		// ==================================================================================== //
		case UPDATE_EMAIL_TEMPLATE + '_PENDING':
			return { ...state, updatingEmailTemplate: true, saving: true };

		case UPDATE_EMAIL_TEMPLATE + '_REJECTED':
			return { ...state, updatingEmailTemplate: false, savingError: true };

		case UPDATE_EMAIL_TEMPLATE + '_FULFILLED':
			let updatedEmailTemplate = { ...action.payload.data.values[0], message_type: 'Email' };
			let newUpdatedEmailTemplatesState = state.emailTemplates.map(email => {
				if (email.id !== updatedEmailTemplate.id) {
					return email;
				} else {
					updatedEmailTemplate.attachments = [...email.attachments];
					return updatedEmailTemplate;
				}
			});
			return {
				...state,
				emailTemplates: newUpdatedEmailTemplatesState,
				updatingEmailTemplate: false,
				saving: false,
			};
		// ==================================================================================== //
		// ================================== SMSTemplates ==================================== //
		// ==================================================================================== //

		//CREATE
		case CREATE_SMS + '_PENDING':
			return { ...state, saving: true };
		case CREATE_SMS + '_REJECTED':
			return { ...state, savingError: true };
		case CREATE_SMS + '_FULFILLED':
			let smsTemplates = [
				...state.smsTemplates,
				{ ...action.payload.data.data.values, message_type: 'SMS' },
			];
			return { ...state, smsTemplates, saving: false };

		// UPDATE
		case UPDATE_SMS + '_PENDING':
			return { ...state, saving: true };
		case UPDATE_SMS + '_REJECTED':
			return { ...state, savingError: true };
		case UPDATE_SMS + '_FULFILLED':
			const updatedSMS = { ...action.payload.data.values[0], message_type: 'SMS' };
			let filteredSMS = state.smsTemplates.filter(sms => sms.uuid !== updatedSMS.uuid);
			filteredSMS.push(updatedSMS);
			return { ...state, smsTemplates: filteredSMS, saving: false };

		// ==================================================================================== //
		// ================================== UPDATE CONTACT ================================== //
		// ==================================================================================== //
		case UPDATE_CONTACT + '_PENDING':
			return { ...state, saving: true };

		case UPDATE_CONTACT + '_REJECTED':
			return { ...state, savingError: true };

		case UPDATE_CONTACT + '_FULFILLED':
			let contactToUpdate = action.payload.contact;
			let newStateUpdateContact = state.contacts.map(contact =>
				contact.uuid === contactToUpdate.uuid ? contactToUpdate : contact,
			);

			return { ...state, contacts: newStateUpdateContact, saving: true };

		// ==================================================================================== //
		// =============================== GET DASHBOARD DATA ================================= //
		// ==================================================================================== //

		case GET_DASH_DATA + '_PENDING':
			return { ...state, fetchingDashboardData: true };

		case GET_DASH_DATA + '_FULFILLED':
			const newDashboardData = action.payload.data.values.data;
			let oldDashData = { ...state.dashboardData };
			oldDashData.subscribers = newDashboardData.subscribers;
			oldDashData.total_leads = newDashboardData.total_leads;
			oldDashData.leads = newDashboardData.leads;
			oldDashData.formSubmissions = newDashboardData.formSubmissions;
			oldDashData.active_campaigns = newDashboardData.active_campaigns;
			oldDashData.formsTop5 = newDashboardData.formsTop5;
			oldDashData.templates_top_5 = newDashboardData.templates_top_5;
			return { ...state, dashboardData: oldDashData, fetchingDashboardData: false };

		case GET_DASH_DATA + '_REJECTED':
			return { ...state, fetchingForms: false };

		case GET_DASH_EMAIL_ACTIVITY + '_PENDING':
			return { ...state, fetchingEmailActivity: true };

		case GET_DASH_EMAIL_ACTIVITY + '_FULFILLED':
			var oldEmailData = { ...state.dashboardData };
			oldEmailData.emails = action.payload.data.values.data.emails;
			oldEmailData.emails_sent = action.payload.data.values.data.emails_sent;
			oldEmailData.emails_opened = action.payload.data.values.data.emails_opened;
			oldEmailData.emails_clicked = action.payload.data.values.data.emails_clicked;
			oldEmailData.opened_rate = action.payload.data.values.data.opened_rate;
			oldEmailData.clicked_rate = action.payload.data.values.data.clicked_rate;
			return { ...state, dashboardData: oldEmailData, fetchingEmailActivity: false };

		case GET_DASH_EMAIL_ACTIVITY + '_REJECTED':
			return { ...state, fetchingEmailActivity: false };

		// ==================================================================================== //
		// ==================================== GET CAMPAIGNS ================================= //
		// ==================================================================================== //

		case GET_CAMPAIGNS + '_PENDING':
			return { ...state, fetchingCampaigns: true };

		case GET_CAMPAIGNS + '_FULFILLED':
			let { campaignsList } = action.payload.data.values;
			return { ...state, campaigns: campaignsList, fetchingCampaigns: false };

		case GET_CAMPAIGNS + '_REJECTED':
			return { ...state, fetchingCampaigns: false };

		// ==================================================================================== //
		// =============================== GET CAMPAIGN DETAILS =============================== //
		// ==================================================================================== //

		case GET_CAMPAIGN_DETAILS + '_PENDING':
			return { ...state, fetchingCampaignDetails: true };

		case GET_CAMPAIGN_DETAILS + '_FULFILLED':
			// destructure values returned from server into variables

			let {
				campaign: campaign_details,
				emails: campaign_details_emails,
				forms: campaign_details_forms,
				tags: campaign_details_tags,
				automations: campaign_details_automations,
			} = action.payload.data.values;

			// map over the campaigns array on state, and add to the existing campaign the new campaign details
			let campaign_details_arr = state.campaigns.map(campaign => {
				if (campaign.uuid === campaign_details.uuid) {
					return {
						automations: campaign_details_automations,
						...campaign_details,
						hasFetchedDetails: true,
					};
				} else {
					return campaign;
				}
			});

			return {
				...state,
				campaigns: campaign_details_arr,
				emailTemplates: campaign_details_emails || state.emailTemplates,
				forms: campaign_details_forms || state.forms,
				tags: campaign_details_tags || state.tags,
				fetchingCampaignDetails: false,
			};

		case GET_CAMPAIGN_DETAILS + '_REJECTED':
			return { ...state, fetchingCampaignDetails: false };

		// ==================================================================================== //
		// =============================== SAVE CAMPAIGN AUTOMATION =============================== //
		// ==================================================================================== //

		case SAVE_CAMPAIGN_AUTOMATION + '_PENDING':
			return { ...state, savingCampaignAutomation: true, saving: true };

		case SAVE_CAMPAIGN_AUTOMATION + '_FULFILLED':
			// destructure values returned from server into variables
			let { uuid: campaign_automation_uuid, automations: campaign_automation_automations } =
				action.payload.data.values;

			// update existing campaign with new arrays
			let updated_campaign_automation = state.campaigns.map(campaign => {
				if (campaign.uuid === campaign_automation_uuid) {
					return {
						...campaign,
						automations: campaign_automation_automations,
					};
				}
				return campaign;
			});

			return {
				...state,
				campaigns: updated_campaign_automation,
				saving: false,
			};

		case SAVE_CAMPAIGN_AUTOMATION + '_REJECTED':
			return { ...state, savingCampaignAutomation: false, savingError: true };

		// ==================================================================================== //
		// =================================== CREATE CAMPAIGN ================================ //
		// ==================================================================================== //

		case CREATE_CAMPAIGN + '_PENDING':
			return { ...state, creatingCampaign: true, saving: true };
		case CREATE_CAMPAIGN + '_FULFILLED':
			let {
				campaign: created_campaign,
				automations: created_automations,
				forms: created_forms,
				tags: created_tags,
				emails: created_emails,
			} = action.payload.data.values;

			let created_campaigns = state.campaigns;
			created_campaign.automations = created_automations;
			created_campaign.hasFetchedDetails = true;

			created_campaigns.push(created_campaign);

			return {
				...state,
				campaigns: created_campaigns,
				emailTemplates: created_emails || state.emailTemplates,
				forms: created_forms || state.forms,
				tags: created_tags || state.tags,
				creatingCampaign: false,
				saving: false,
			};

		case CREATE_CAMPAIGN + '_REJECTED':
			return { ...state, creatingCampaign: false, savingError: true };

		// ==================================================================================== //
		// =================================== UPDATE CAMPAIGN ================================ //
		// ==================================================================================== //

		case UPDATE_CAMPAIGN + '_PENDING':
			return { ...state, updatingCampaign: true, saving: true };
		case UPDATE_CAMPAIGN + '_FULFILLED':
			let { campaign: updated_campaign } = action.payload.data.values;

			let updated_campaigns = state.campaigns;

			updated_campaigns = updated_campaigns.map(campaign => {
				if (campaign.uuid === updated_campaign.uuid) {
					return updated_campaign;
				} else {
					return campaign;
				}
			});

			return {
				...state,
				campaigns: updated_campaigns,
				updatingCampaign: false,
				saving: false,
			};

		case UPDATE_CAMPAIGN + '_REJECTED':
			return { ...state, updatingCampaign: false, savingError: true };

		case DELETE_CAMPAIGN + '_PENDING':
			return { ...state, saving: true };
		case DELETE_CAMPAIGN + '_FULFILLED':
			let temp_campaigns = state.campaigns.filter(
				campaign => campaign.uuid !== action.payload.data.values.uuid,
			);
			return { ...state, campaigns: temp_campaigns, saving: false };
		case DELETE_CAMPAIGN + '_REJECTED':
			return { ...state, savingError: true };
		// ==================================================================================== //
		// ======================================= GET TAGS =================================== //
		// ==================================================================================== //

		case GET_TAGS + '_PENDING':
			return { ...state, fetchingTags: true };

		case GET_TAGS + '_FULFILLED':
			let { tags } = action.payload.data.values;
			return { ...state, tags: tags, fetchingTags: false };

		case GET_TAGS + '_REJECTED':
			return { ...state, fetchingTags: false };

		// ==================================================================================== //
		// ======================================= GET TAG DETAILS =================================== //
		// ==================================================================================== //

		case GET_TAG_DETAILS + '_PENDING':
			return { ...state, fetchingTagDetails: true };

		case GET_TAG_DETAILS + '_FULFILLED':
			let {
				entrypoints: tag_details_entrypoints,
				automations: tag_details_automations,
				uuid: tag_details_uuid,
			} = action.payload.data.values;

			let new_tag_details = state.tags.map(tag => {
				if (tag.uuid === tag_details_uuid) {
					return {
						...tag,
						entrypoints: tag_details_entrypoints,
						automations: tag_details_automations,
						hasFetchedDetails: true,
					};
				}
				return tag;
			});
			return { ...state, tags: new_tag_details, fetchingTagDetails: false };

		case GET_TAG_DETAILS + '_REJECTED':
			return { ...state, fetchingTagDetails: false };

		// ==================================================================================== //
		// ======================================= CREATE TAG =================================== //
		// ==================================================================================== //

		case CREATE_TAG + '_PENDING':
			return { ...state, creatingTag: true, saving: true };

		case CREATE_TAG + '_FULFILLED':
			let { tag: create_tag } = action.payload.data.values;
			let create_tags = state.tags;
			create_tags.push(create_tag);
			return { ...state, tags: create_tags, creatingTag: false, saving: false };

		case CREATE_TAG + '_REJECTED':
			let err = 'Error';
			let { response } = action.payload;
			if (response && response.data) {
				err = response.data.err || 'Error';
			}
			return { ...state, err, creatingTag: false, savingError: true };

		// ==================================================================================== //
		// ======================================= EDIT TAG =================================== //
		// ==================================================================================== //

		case EDIT_TAG + '_PENDING':
			return { ...state, editingTag: true, saving: true };

		case EDIT_TAG + '_FULFILLED':
			let { tag: edit_tag } = action.payload.data.values;
			let edit_tags = state.tags;

			// modify the tag on state that was renamed
			let new_edit_tags = [];

			edit_tags.forEach(tag => {
				if (edit_tag.uuid === tag.uuid) {
					return new_edit_tags.push({ ...tag, ...edit_tag });
				}
				return new_edit_tags.push(tag);
			});

			return { ...state, tags: new_edit_tags, editingTag: false, saving: false };

		case EDIT_TAG + '_REJECTED':
			return { ...state, editingTag: false, savingError: true };

		// ==================================================================================== //
		// ==================================== DELETE TAG ===================================== //
		// ==================================================================================== //

		case DELETE_TAG + '_PENDING':
			return { ...state, saving: true };

		case DELETE_TAG + '_FULFILLED':
			let temp_tags = state.tags.filter(tag => tag.uuid !== action.payload.data.values.uuid);
			return { ...state, tags: temp_tags, saving: false };

		case DELETE_TAG + '_REJECTED':
			return { ...state, saving: false };

		// ==================================================================================== //
		// ==================================== GET FORMS ===================================== //
		// ==================================================================================== //

		case GET_FORMS + '_PENDING':
			return { ...state, fetchingForms: true };

		case GET_FORMS + '_FULFILLED':
			let { forms, campaigns } = action.payload.data.values;
			return { ...state, forms, campaigns, fetchingForms: false };

		case GET_FORMS + '_REJECTED':
			return { ...state, fetchingForms: false };

		// ==================================================================================== //
		// ================================ GET FORM DETAILS ================================== //
		// ==================================================================================== //

		case GET_FORM_DETAILS + '_PENDING':
			return { ...state, fetchingFormDetails: true };

		case GET_FORM_DETAILS + '_FULFILLED':
			let form_details = action.payload.data.values.form;
			// map over the forms array on state, and add to the existing form the new form details
			let form_details_arr = state.forms.map(form => {
				if (form.uuid === form_details.uuid) {
					return {
						...form_details,
						hasFetchedDetails: true,
					};
				} else {
					return form;
				}
			});

			return {
				...state,
				forms: form_details_arr,
				fetchingFormDetails: false,
			};

		case GET_FORM_DETAILS + '_REJECTED':
			return { ...state, fetchingFormDetails: false };

		// ==================================================================================== //
		// =================================== CREATE FORM ==================================== //
		// ==================================================================================== //

		case CREATE_FORM + '_PENDING':
			return { ...state, creatingForm: true, saving: true };

		case CREATE_FORM + '_FULFILLED':
			let created_form = action.payload.data.values.form;

			let created_forms_arr = state.forms;
			created_forms_arr.push(created_form);

			return {
				...state,
				forms: created_forms_arr,
				creatingForm: false,
				saving: false,
			};

		case CREATE_FORM + '_REJECTED':
			return { ...state, creatingForm: false, savingError: true };

		// ==================================================================================== //
		// ============================= UPDATE FORM DOCUMENTS ================================ //
		// ==================================================================================== //

		case UPDATE_FORM_DOCUMENTS:
			let updated_forms_documents_arr = state.forms.map(form => {
				if (!form.form_documents) {
					form.form_documents = [];
				}
				if (form.uuid !== action.payload.uuid) {
					return form;
				} else {
					form.form_documents.push(action.payload.file);
					return form;
				}
			});
			return { ...state, forms: updated_forms_documents_arr };
		// ==================================================================================== //
		// =================================== UPDATE FORM ==================================== //
		// ==================================================================================== //

		case UPDATE_FORM + '_PENDING':
			return { ...state, updatingForm: true, saving: true };

		case UPDATE_FORM + '_FULFILLED':
			let updated_form = action.payload.data.values.form;

			// map over the forms array on state, and edit the existing form
			let updated_forms_arr = state.forms.map(form =>
				form.uuid === updated_form.uuid ? updated_form : form,
			);

			return {
				...state,
				forms: updated_forms_arr,
				updatingForm: false,
				saving: false,
			};

		case UPDATE_FORM + '_REJECTED':
			return { ...state, updatingForm: false, savingError: true };

		// ==================================================================================== //
		// =================================== UPDATE FORM BUILDER ==================================== //
		// ==================================================================================== //

		case UPDATE_FORM_BUILDER + '_PENDING':
			return { ...state, updatingFormBuilder: true, saving: true };

		case UPDATE_FORM_BUILDER + '_FULFILLED':
			let updated_form_builder = action.payload.data.values.form;

			// map over the forms array on state, and edit the existing form
			let updated_form_builder_arr = state.forms.map(form =>
				form.uuid === updated_form_builder.uuid ? updated_form_builder : form,
			);

			return {
				...state,
				forms: updated_form_builder_arr,
				updatingFormBuilder: false,
				saving: false,
			};

		case UPDATE_FORM_BUILDER + '_REJECTED':
			return { ...state, updatingFormBuilder: false, savingError: true };

		// ==================================================================================== //
		// =================================== DELETE FORM ==================================== //
		// ==================================================================================== //

		case DELETE_FORM + '_PENDING':
			return { ...state, saving: true };

		case DELETE_FORM + '_FULFILLED':
			let temp_forms = state.forms.filter(
				form => form.uuid !== action.payload.data.values.uuid,
			);
			return { ...state, forms: temp_forms, saving: false };

		case DELETE_FORM + '_REJECTED':
			return { ...state, savingError: true };

		// ==================================================================================== //
		// =================================== UPDATE FORM STATUS ==================================== //
		// ==================================================================================== //

		case UPDATE_FORM_STATUS + '_PENDING':
			return { ...state, saving: true };

		case UPDATE_FORM_STATUS + '_FULFILLED':
			let temp_form = state.forms.map(form => {
				if (form.uuid === action.payload.data.values.uuid) {
					form.is_active = action.payload.data.values.status;
				}
				return form;
			});
			return { ...state, forms: temp_form, saving: false };

		case UPDATE_FORM_STATUS + '_REJECTED':
			return { ...state, savingError: true };
		// ==================================================================================== //
		// ================================== GET COMPANIES =================================== //
		// ==================================================================================== //
		case GET_COMPANIES + '_FULFILLED':
			return { ...state, companies: action.payload.data.values };

		case GET_COMPANIES + '_PENDING':
			return { ...state, fetchingCompanies: true };

		case GET_COMPANIES + '_REJECTED':
			return { ...state, fetchingCompanies: false };
		// ==================================================================================== //
		// ================================== GET ACTIVITIES ================================== //
		// ==================================================================================== //
		case GET_ORG_ACTIVITIES + '_FULFILLED':
			return {
				...state,
				activities: action.payload.data.values.data.activities,
				fetchingActivities: false,
			};

		case GET_ORG_ACTIVITIES + '_PENDING':
			return { ...state, fetchingActivities: true };

		case GET_ORG_ACTIVITIES + '_REJECTED':
			return { ...state, fetchingActivities: false };
		// ==================================================================================== //
		// =============================== TOGGLECONFIRMATION  ================================ //
		// ==================================================================================== //
		case TOGGLE_CONFIRMATION:
			const { saving, savingError } = action.payload;
			return { ...state, saving, savingError };
		// ==================================================================================== //
		// ==================================== UPDATE_ORGANIZATION  ====================================== //
		// ==================================================================================== //

		case UPDATE_ORGANIZATION + '_FULFILLED':
			window.location.replace('/crm');
			return { ...state, saving: false };
		case UPDATE_ORGANIZATION + '_PENDING':
			return { ...state, saving: true };
		case UPDATE_ORGANIZATION + '_REJECTED':
			return { ...state, savingError: true };

		// ==================================================================================== //
		// ==================================== DEFAULT  ====================================== //
		// ==================================================================================== //

		default:
			return state;
	}
}

// ==================================================================================== //
// ==================================== CONTACTS ====================================== //
// ==================================================================================== //

export function getContact(contact_uuid, organization_uuid) {
	const contact = axios.get(
		`api/v1/contact/${contact_uuid}?organization_uuid=${organization_uuid}`,
	);
	return {
		type: GET_CONTACT,
		payload: contact,
	};
}

export function uploadContactFile(contact_file) {
	return (dispatch, getState) => {
		dispatch({
			type: UPLOAD_CONTACT_FILE,
			payload: { ...contact_file },
		});
	};
}

export function deleteContactFile(file_key) {
	return (dispatch, getState) => {
		const delete_file = axios.delete(`/api/v1/contact/file`, { data: { file_key: file_key } });
		dispatch({
			type: DELETE_CONTACT_FILE,
			payload: delete_file,
		});
	};
}

export function uploadEmailAttachment(email_attachment) {
	email_attachment.Path = email_attachment.Key.substr(0);
	return (dispatch, getState) => {
		dispatch({
			type: UPLOAD_EMAIL_ATTACHMENT,
			payload: { ...email_attachment },
		});
	};
}

export function deleteEmailAttachment(file_key, organization_uuid) {
	return (dispatch, getState) => {
		const delete_email_attachment = axios.delete(`/api/v1/email-attachment`, {
			data: { file_key },
		});
		dispatch({
			type: DELETE_EMAIL_ATTACHMENT,
			payload: delete_email_attachment,
		});
	};
}

export function createContact(contact) {
	return {
		type: CREATE_CONTACT,
		payload: contact,
	};
}

export function updateContact(contact) {
	const load = {
		index: initialState.contacts.findIndex(e => e.uuid === contact.uuid),
		contact: contact,
	};
	return {
		type: UPDATE_CONTACT,
		payload: load,
	};
}

export function toggleIsActive(id) {
	const isActive = axios.put(`api/v1/contact_isactive/${id}`);
	return {
		type: TOGGLE_ISACTIVE,
		payload: isActive,
	};
}

// ==================================================================================== //
// ================================= EMAIL TEMPLATES ================================== //
// ==================================================================================== //

export function getTemplates(uuid) {
	return (dispatch, getState) => {
		let state = getState();

		let { emailTemplates } = state.CRMReducer;

		if (emailTemplates.length) {
			dispatch({
				type: GET_TEMPLATES,
				payload: null,
			});
		} else {
			const fetch_email_templates = axios.get('/api/v1/email-templates/' + uuid);
			dispatch({
				type: GET_TEMPLATES,
				payload: fetch_email_templates,
			});
		}
	};
}

export function createEmailTemplate(email) {
	const newEmailTemplate = axios.post(`api/v1/email-templates`, email);
	return {
		type: CREATE_EMAIL_TEMPLATE,
		payload: newEmailTemplate,
	};
}

export function updateEmailTemplate(email) {
	const updatedEmailTemplate = axios.put(`/api/v1/email-templates/` + email.id, email);
	return {
		type: UPDATE_EMAIL_TEMPLATE,
		payload: updatedEmailTemplate,
	};
}

// ==================================================================================== //
// ====================================== FORMS ======================================= //
// ==================================================================================== //

export function getForms() {
	return (dispatch, getState) => {
		let state = getState();

		let { forms } = state.CRMReducer;

		if (forms.length) {
			dispatch({
				type: GET_FORMS,
				payload: null,
			});
		} else {
			const fetch_forms = axios.get('/api/v1/forms/all');
			dispatch({
				type: GET_FORMS,
				payload: fetch_forms,
			});
		}
	};
}

export function getFormDetails(form_uuid) {
	return (dispatch, getState) => {
		let state = getState();

		// check if redux state already has form details based on uuid
		let form_details = state.CRMReducer.forms.filter(
			form => form.uuid === form_uuid && form.hasFetchedDetails,
		);

		// if have already fetched details, do not fetch again
		if (form_details.length) {
			dispatch({
				type: GET_FORM_DETAILS,
				payload: null,
			});
		} else {
			const form = axios.get('/api/v1/forms/by-uuid/' + form_uuid);

			dispatch({
				type: GET_FORM_DETAILS,
				payload: form,
			});
		}
	};
}

export function createForm(form_details) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			let create_form = axios.post('/api/v1/forms/new', form_details);

			dispatch({
				type: CREATE_FORM,
				payload: create_form,
			}).then(result => {
				// redirect page to the new form
				let { uuid } = result.value.data.values.form;
				resolve({});
				return history.push(`/crm/forms/${uuid}`);
			});
		})
	};
}
export function updateFormDocuments(file_to_add, uuid) {
	return {
		type: UPDATE_FORM_DOCUMENTS,
		payload: { file_to_add, uuid },
	};
}

export function deleteFormDownloadFiles(form_uuid, file_key) {
	return (dispatch, getState) => {
		const delete_form_downloadables = axios.delete(`/api/v1/forms/downloads/` + form_uuid, {
			data: { file_key },
		});
		dispatch({
			type: DELETE_FORM_DOWNLOADABLES,
			payload: delete_form_downloadables,
		});
	};
}
export function updateForm(form_details) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			let update_form = axios.put(`/api/v1/forms/by-uuid/${form_details.uuid}`, form_details);
			dispatch({
				type: UPDATE_FORM,
				payload: update_form,
			}).then(() => {
				resolve({});
			})
		})

	}
}

export function updateFormBuilder(form_data, uuid) {
	let update_form = axios.put(`/api/v1/forms/form-builder/${uuid}`, { form_data });

	return {
		type: UPDATE_FORM_BUILDER,
		payload: update_form,
	};
}

export function deleteForm(uuid) {
	let delete_form = axios.delete(`/api/v1/forms/${uuid}`);

	return {
		type: DELETE_FORM,
		payload: delete_form,
	};
}

export function updateFormStatus(uuid, status) {
	let change_status = axios.put(`/api/v1/forms/change-status/${uuid}`, { status: status });
	return {
		type: UPDATE_FORM_STATUS,
		payload: change_status,
	};
}
// ==================================================================================== //
// ==================================== COMPANIES ===================================== //
// ==================================================================================== //

export function getCompanies(uuid) {
	const companies = axios.get('api/v1/companies/' + uuid);
	return {
		type: GET_COMPANIES,
		payload: companies,
	};
}

// ==================================================================================== //
// ==================================== DASHBOARD ===================================== //
// ==================================================================================== //

export function getDashEmailActivity(org_id, org_uuid) {
	return (dispatch, getState) => {
		let state = getState();
		let { emails } = state.CRMReducer.dashboardData;

		if (emails.length > 0) {
			dispatch({
				type: GET_DASH_EMAIL_ACTIVITY,
				payload: null,
			});
		} else {
			const fetch_dashboard_email_activity = axios.get(
				`/api/v1/dashboard-emailactivity?org_uuid=${org_uuid}`,
			);
			dispatch({
				type: GET_DASH_EMAIL_ACTIVITY,
				payload: fetch_dashboard_email_activity,
			});
		}
	};
}

export function getDashData(org_id, org_uuid, monthsToFetch, formsToFetch) {
	return (dispatch, getState) => {
		let state = getState();
		let { leads, formsTop5, formSubmissions } = state.CRMReducer.dashboardData;

		if (leads.length > 0 && formsTop5.length > 0 && formSubmissions.length > 0) {
			dispatch({
				type: GET_DASH_DATA,
				payload: null,
			});
		} else {
			const fetch_dash_data = axios.get(
				`/api/v1/dashboard-data?monthsToFetch=${monthsToFetch}&org_uuid=${org_uuid}`,
			);
			dispatch({
				type: GET_DASH_DATA,
				payload: fetch_dash_data,
			});
		}
	};
}
// ==================================================================================== //
// ==================================== ACTIVITIES ==================================== //
// ==================================================================================== //

export function getOrgActivities() {
	return (dispatch, getState) => {
		let state = getState();
		let { activities } = state.CRMReducer;
		if (activities.length) {
			dispatch({
				type: GET_ORG_ACTIVITIES,
				payload: null,
			});
		} else {
			const fetch_activities_data = axios.get('/api/v1/organization-activities');
			dispatch({
				type: GET_ORG_ACTIVITIES,
				payload: fetch_activities_data,
			});
		}
	};
}
// ==================================================================================== //
// =============================== TOGGLE CONFIRMATION ================================ //
// ==================================================================================== //
export function toggleConfirmation(saving, savingError) {
	return {
		type: TOGGLE_CONFIRMATION,
		payload: {
			saving,
			savingError,
		},
	};
}

// ==================================================================================== //
// =============================== UPDATE_ORGANIZATION ================================ //
// ==================================================================================== //
export function updateOrganization(payload) {
	console.log(payload, '---------update organization payload----------');
	return (dispatch, getState) => {
		const update = axios.put('/api/v1/updateOwnerOrganization', payload);
		update
			.then(() => {
				dispatch({
					type: UPDATE_ORGANIZATION,
					payload: update,
				});
				console.log(payload, ' -------------inside Actions Apii update---------------');
				payload.history.push('/crm');
			})
			.catch(err => {
				Swal.fire({
					title: 'Alert',
					text: `The Organization already exist`,
					icon: 'info',
					showConfirmButton: true,
				});
			});
	};
}
export { createSMS, updateSMS, deleteMessageTemplate } from './actions/message_center_actions';
export { getTags, getTagDetails, createTag, editTag, deleteTag } from './actions/crm_tags_actions';
export {
	getCampaigns,
	getCampaignDetails,
	saveCampaignAutomation,
	createCampaign,
	updateCampaign,
	deleteCampaign,
} from './actions/crm_campaigns_actions';
