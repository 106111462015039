import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import { reducer as toastrReducer } from 'react-redux-toastr';
import thunk from 'redux-thunk';

import GlobalReducer from './GlobalReducer';
import CRMReducer from './CRMReducer';
import SystemsReducer from './SystemsReducer';
import ascensionSeriesReducer from '../views/emailcopy/reducer/ascensionSeriesReducer';
import compaignsReducer from '../views/emailcopy/reducer/compaignsReducer';
import customerReducer from '../views/emailcopy/reducer/customerReducer';
import templatesReducer from '../views/emailcopy/reducer/templatesReducer';
import blogSequenceReducer from '../views/emailcopy/reducer/blogSequenceReducer';
import BloggerOutreachReducer from '../views/emailcopy/reducer/BloggerOutreachReducer';
import bondingSequenceReducer from '../views/emailcopy/reducer/bondingSequenceReducer';
import businessDevelopmentReducer from '../views/emailcopy/reducer/businessDevelopmentReducer';
import businessOpportunityReducer from '../views/emailcopy/reducer/businessOpportunityReducer';
import coldSimpleProspectingEmailReducer from '../views/emailcopy/reducer/coldSimpleProspectingEmailReducer';
import directRevenueReducer from '../views/emailcopy/reducer/directRevenueReducer';
import dogTrainingReducer from '../views/emailcopy/reducer/dogTrainingReducer';
import drawingReducer from '../views/emailcopy/reducer/drawingReducer';
import emailViewReducer from '../views/emailcopy/reducer/emailViewReducer';
import evergreenWebinarReplayReducer from '../views/emailcopy/reducer/evergreenWebinarReplayReducer';
import financeReducer from '../views/emailcopy/reducer/financeReducer';
import flasSAleReducer from '../views/emailcopy/reducer/flasSAleReducer';
import freeShippingReducer from '../views/emailcopy/reducer/freeShippingReducer';
import golfReducer from '../views/emailcopy/reducer/golfReducer';
import greenEnergyReducer from '../views/emailcopy/reducer/greenEnergyReducer';
import guitarNicheReducer from '../views/emailcopy/reducer/guitarNicheReducer';
import leadMagnetSequenceMultipleVideoReducer from '../views/emailcopy/reducer/leadMagnetSequenceMultipleVideoReducer';
import leadMagnetSequenceReportReducer from '../views/emailcopy/reducer/leadMagnetSequenceReportReducer';
import lmsVideoReducer from '../views/emailcopy/reducer/lmsVideoReducer';
import menFitnessReducer from '../views/emailcopy/reducer/menFitnessReducer';
import mensDatingReducer from '../views/emailcopy/reducer/mensDatingReducer';
import mensExBackReducer from '../views/emailcopy/reducer/mensExBackReducer';
import mindBrainReducer from '../views/emailcopy/reducer/mindBrainReducer';
import paleoLifestyleReducer from '../views/emailcopy/reducer/paleoLifestyleReducer';
import personalDevelopmentsReducer from '../views/emailcopy/reducer/personalDevelopmentsReducer';
import photographyNicheReducer from '../views/emailcopy/reducer/photographyNicheReducer';
import physicalProductReducer from '../views/emailcopy/reducer/physicalProductReducer';
import podcastOutreachReducer from '../views/emailcopy/reducer/podcastOutreachReducer';
import postWabinarReducer from '../views/emailcopy/reducer/postWabinarEamailReducer';
import prewebinarofferReducer from '../views/emailcopy/reducer/prewebinarofferReducer';
import realEstateReducer from '../views/emailcopy/reducer/realEstateReducer';
import reEngagementReducer from '../views/emailcopy/reducer/reEngagementReducer';
import salesLetterPromoSequenceReducer from '../views/emailcopy/reducer/salesLetterPromoSequenceReducer';
import shoppingCartAbandonmentReducer from '../views/emailcopy/reducer/shoppingCartAbandonmentReducer';
import strategySessionReducer from '../views/emailcopy/reducer/strategySessionReducer';
import surveySequenceReducer from '../views/emailcopy/reducer/surveySequenceReducer';
import survivalNicheReducer from '../views/emailcopy/reducer/survivalNicheReducer';
import VSLPromoSequenceReducer from '../views/emailcopy/reducer/VSLPromoSequenceReducer';
import waitingListRelaunchReducer from '../views/emailcopy/reducer/waitingListRelaunchReducer';
import webinarPromoReducer from '../views/emailcopy/reducer/webinarPromoReducer';
import webinarReplaySeqReducer from '../views/emailcopy/reducer/evergreenWebinarReplayReducer';
import webinarReplaySequenceReducer from '../views/emailcopy/reducer/webinarReplaySequenceReducer';
import weddingsReducer from '../views/emailcopy/reducer/weddingsReducer';
import womenExbackReducer from '../views/emailcopy/reducer/womenExbackReducer';
import womenHealthReducer from '../views/emailcopy/reducer/womenHealthReducer';
import customizeEmailReducer from '../views/emailcopy/reducer/customizeEmailReducer';

/****************8*********************** Start Webinars Reducers*******************************/
import campaignWebReducer from '../ducks/reducer/campaignWebReducer';
import questionForm1Reducer from '../ducks/reducer/questionForm1Reducer';
import questionForm2Reducer from '../ducks/reducer/questionForm2Reducer';
import questionForm3Reducer from '../ducks/reducer/questionForm3Reducer';
import questionForm4Reducer from '../ducks/reducer/questionForm4Reducer';
import questionForm5Reducer from '../ducks/reducer/questionForm5Reducer';
import questionForm6Reducer from '../ducks/reducer/questionForm6Reducer';
import allCompaignsReducer from '../ducks/reducer/allCompaignsReducer';
import stepsReducer from '../ducks/reducer/stepsReducer';

/****************8*********************** End Webinars Reducers*******************************/

/****************8*********************** Start VSL  Reducers*******************************/
import campaigsVSLReducer from '../views/videosales/reducer/campaigsVSLReducer';
import slidesReducer from '../views/videosales/reducer/slidesReducer';

/****************8*********************** End VSL  Reducers*******************************/
let middleware = [promiseMiddleware(), thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const buildReducers = combineReducers({
	GlobalReducer,
	CRMReducer,
	SystemsReducer,
	compaignsReducer,
	customerReducer,
	templatesReducer,
	ascensionSeriesReducer,
	BloggerOutreachReducer,
	blogSequenceReducer,
	bondingSequenceReducer,
	businessDevelopmentReducer,
	businessOpportunityReducer,
	coldSimpleProspectingEmailReducer,
	directRevenueReducer,
	dogTrainingReducer,
	drawingReducer,
	emailViewReducer,
	evergreenWebinarReplayReducer,
	financeReducer,
	flasSAleReducer,
	freeShippingReducer,
	golfReducer,
	greenEnergyReducer,
	guitarNicheReducer,
	leadMagnetSequenceMultipleVideoReducer,
	leadMagnetSequenceReportReducer,
	lmsVideoReducer,
	menFitnessReducer,
	mensDatingReducer,
	mensExBackReducer,
	mindBrainReducer,
	paleoLifestyleReducer,
	personalDevelopmentsReducer,
	photographyNicheReducer,
	physicalProductReducer,
	podcastOutreachReducer,
	postWabinarReducer,
	prewebinarofferReducer,
	realEstateReducer,
	reEngagementReducer,
	salesLetterPromoSequenceReducer,
	shoppingCartAbandonmentReducer,
	strategySessionReducer,
	surveySequenceReducer,
	survivalNicheReducer,
	VSLPromoSequenceReducer,
	waitingListRelaunchReducer,
	webinarPromoReducer,
	webinarReplaySeqReducer,
	webinarReplaySequenceReducer,
	weddingsReducer,
	womenExbackReducer,
	womenHealthReducer,
	customizeEmailReducer,
	toastr: toastrReducer,
	/****************8*********************** Start Webinars Reducers*******************************/
	campaignWebReducer,
	questionForm1Reducer,
	questionForm2Reducer,
	questionForm3Reducer,
	questionForm4Reducer,
	questionForm5Reducer,
	questionForm6Reducer,
	allCompaignsReducer,
	stepsReducer,
	/****************8*********************** Start VSL Reducers*******************************/
	campaigsVSLReducer,
	slidesReducer,
});

export default createStore(buildReducers, composeEnhancers(applyMiddleware(...middleware)));
