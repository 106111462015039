import {
	SAVE_QUESTION_FORM,
	GET_QUESTION_FORM,
	UPDATE_QUESTION_FORM1,
} from '../actions/webinarActions/allActions';

const initialSate = {
	questionForm1Array: [],
	getquestionForm1Array: [],
	update_questionForm1Array: [],
};
const questionForm1Reducer = (state = initialSate, action) => {
	switch (action.type) {
		case SAVE_QUESTION_FORM: {
			return {
				...state,
				questionForm1Array: action.response,
			};
		}
		case GET_QUESTION_FORM: {
			return {
				...state,
				getquestionForm1Array: action.response,
			};
		}
		case UPDATE_QUESTION_FORM1: {
			return {
				...state,
				update_questionForm1Array: action.response,
			};
		}
		default:
			return state;
	}
};
export default questionForm1Reducer;
