import { SAVE_QUESTIONFROM3, STEPS_QUESTIONFROM3 } from '../actions/webinarActions/allActions';

const initialSate = {
	questionform3Array: [],
	stepsform3Array: [],
};
const questionForm3Reducer = (state = initialSate, action) => {
	switch (action.type) {
		case SAVE_QUESTIONFROM3: {
			return {
				...state,
				questionform3Array: action.response,
			};
		}
		case STEPS_QUESTIONFROM3: {
			return {
				...state,
				stepsform3Array: action.response,
			};
		}
		default:
			return state;
	}
};
export default questionForm3Reducer;
