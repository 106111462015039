import axios from 'axios';

// ==================================================================================== //
// ===================================== ACTIONS ====================================== //
// ==================================================================================== //

const GET_TAGS = 'GET_TAGS';
const GET_TAG_DETAILS = 'GET_TAG_DETAILS';
const CREATE_TAG = 'CREATE_TAG';
const EDIT_TAG = 'EDIT_TAG';
const DELETE_TAG = 'DELETE_TAG';

// ============================================================ //
// ======================= GET TAGS =========================== //
// ============================================================ //

export function getTags() {
	return (dispatch, getState) => {
		let state = getState();
		let { tags } = state.CRMReducer;

		// if (tags.length) {
		// 	// already fetched tags from store
		// 	dispatch({
		// 		type: GET_TAGS,
		// 		payload: null,
		// 	});
		// } else {
		// need to fetch tags
		const fetchTags = axios({
			url: '/api/v1/tags/all',
			method: 'GET',
		});
		dispatch({
			type: GET_TAGS,
			payload: fetchTags,
		});
		// }
	};
}

// ============================================================ //
// =================== GET TAG DETAILS ======================== //
// ============================================================ //

export function getTagDetails(uuid) {
	const fetchTagDetails = axios({
		url: '/api/v1/tags/' + uuid,
		method: 'GET',
	});
	return {
		type: GET_TAG_DETAILS,
		payload: fetchTagDetails,
	};
}

// ============================================================ //
// ====================== CREATE TAG ========================== //
// ============================================================ //

export function createTag(tag_name) {
	const fetchTags = axios({
		url: '/api/v1/tags',
		method: 'POST',
		data: {
			tag_name,
		},
	});

	return {
		type: CREATE_TAG,
		payload: fetchTags,
	};
}

// ============================================================ //
// ======================= EDIT TAG =========================== //
// ============================================================ //

export function editTag(tag) {
	const editTag = axios({
		url: '/api/v1/tags/' + tag.uuid,
		method: 'PUT',
		data: {
			name: tag.name,
		},
	});

	return {
		type: EDIT_TAG,
		payload: editTag,
	};
}

export function deleteTag(tag_uuid, tag_id) {
	const delete_tag = axios.delete(`api/v1/tags/${tag_uuid}/${tag_id}`);
	return {
		type: DELETE_TAG,
		payload: delete_tag,
	};
}
