import {
	GET_ALL_SLIDES_NAMES,
	GET_ALL_SLIDE_DETAILS,
	GET_CAMPAIGN_DETAILS,
	SAVE_SLIDE_DETAILS,
	UPDATE_CUSTOM_SLIDE_DETAILS,
	DELETE_THIS_SLIDE,
} from '../actions/allActions';

const initialSate = {
	sildes_names_array: [],
	true_silde_details_array: [],
	false_silde_details_array: [],
	campaign_silde_details_array: [],
	updated_campaign_silde_details_array: [],
	update_message: '',
};
const slidesReducer = (state = initialSate, action) => {
	switch (action.type) {
		case GET_ALL_SLIDES_NAMES: {
			return {
				...state,
				sildes_names_array: action.response,
			};
		}
		case SAVE_SLIDE_DETAILS: {
			return {
				...state,
				updated_campaign_silde_details_array: action.response,
				campaign_silde_details_array: action.response,
			};
		}
		case UPDATE_CUSTOM_SLIDE_DETAILS: {
			return {
				...state,
				update_message: action.response,
				// campaign_silde_details_array: action.response,
			};
		}
		case GET_CAMPAIGN_DETAILS: {
			return {
				...state,
				campaign_silde_details_array: action.response,
			};
		}
		case GET_ALL_SLIDE_DETAILS: {
			return {
				...state,
				true_silde_details_array: action.response.true_vsl_slides_detail,
				false_silde_details_array: action.response.false_vsl_slides_detail,
			};
		}
		case DELETE_THIS_SLIDE: {
			var campaign_silde_details_array = state.campaign_silde_details_array.filter(
				item => item.id !== action.id,
			);
			return {
				...state,
				campaign_silde_details_array,
			};
		}
		default:
			return state;
	}
};
export default slidesReducer;
