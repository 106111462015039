import axios from 'axios';

const CREATE_SMS = 'CREATE_SMS';
const UPDATE_SMS = 'UPDATE_SMS';

const DELETE_MESSAGE_TEMPLATE = 'DELETE_MESSAGE_TEMPLATE';

export function createSMS(messageInfo) {
	console.log(messageInfo);
	return dispatch => {
		let postSMS = axios({
			url: '/api/v1/sms',
			method: 'POST',
			data: messageInfo,
		});
		dispatch({
			type: CREATE_SMS,
			payload: postSMS,
		});
	};
}
export function updateSMS(messageInfo) {
	console.log(messageInfo);
	return dispatch => {
		let postSMS = axios({
			url: `/api/v1/sms/${messageInfo.uuid}`,
			method: 'PUT',
			data: messageInfo,
		});

		dispatch({
			type: UPDATE_SMS,
			payload: postSMS,
		});
	};
}
export function deleteMessageTemplate(templateInfo) {
	return dispatch => {
		let removeTemplate = axios({
			url: '/api/v1/message-center/' + templateInfo.type,
			method: 'DELETE',
			data: { id: templateInfo.id, uuid: templateInfo.uuid },
		});
		dispatch({
			type: DELETE_MESSAGE_TEMPLATE,
			payload: removeTemplate,
		});
	};
}
