import axios from 'axios';

import Swal from 'sweetalert2';
// ==================================================================================== //
// ===================================== ACTIONS ====================================== //
// ==================================================================================== //
const GET_PACKAGES = 'GET_PACKAGES';
const GET_All_APPS = 'GET_All_APPS';
const UPDATE_PACKAGES = 'UPDATE_PACKAGES';

// ============================================================ //
// =================== GET PACKAGES =========================== //
// ============================================================ //
export function getPackages() {
	return (dispatch, getState) => {
		let state = getState();

		let { packages } = state.SystemsReducer;

		if (packages.length) {
			dispatch({
				type: GET_PACKAGES,
				payload: null,
			});
		} else {
			const fetchPackages = axios({
				url: '/api/v1/packages/all',
				method: 'get',
			});
			dispatch({
				type: GET_PACKAGES,
				payload: fetchPackages,
			});
		}
	};
}

// ============================================================ //
// =================== UPDATE PACKAGE ========================= //
// ============================================================ //
export function updatePackages(body, id) {
	return dispatch => {
		if (id === 'new')
		{
			Swal.fire({
				title: 'Are you sure?',
				text: 'You sure you want to add this package.',
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then(result => {
				if (result.isConfirmed) {
					const updatePackages = axios({url: '/api/v1/package/create',method: 'post',data: body,});
			        dispatch({type: UPDATE_PACKAGES,payload: updatePackages,});
				} 
			});

		}
		else 
		{

			Swal.fire({
				title: 'Are you sure?',
				text: `Are you sure you want to update package:${body.title}?`,
				icon: 'question',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			}).then(result => {
				if (result.isConfirmed) {
					const updatePackages = axios({url: `/api/v1/package/update/${id}`,method: 'put',data: body,});
			        dispatch({type: UPDATE_PACKAGES,payload: updatePackages,});
				} 
			});
			
		}





	};
}

// ============================================================ //
// =================== GET APPS =============================== //
// ============================================================ //
export function getAllApps() {
	return (dispatch, getState) => {
		let state = getState();

		let { allApplications } = state.SystemsReducer;

		if (allApplications.length) {
			dispatch({
				type: GET_All_APPS,
				payload: null,
			});
		} else {
			const fetchApps = axios({
				url: '/api/v1/applications',
				method: 'get',
			});
			dispatch({
				type: GET_All_APPS,
				payload: fetchApps,
			});
		}
	};
}
