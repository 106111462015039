import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import jwt from 'jwt-simple';
import { updateImage, updateImageURL } from '../ducks/GlobalReducer';

class Header extends React.Component {
	state = {
		dropdown: false,
		session: '',
		profile_img_url: null,
	};
	componentDidMount = async () => {
		let existingSession = sessionStorage.getItem('proxyLogin');
		if (existingSession) {
			let session = jwt.decode(existingSession, process.env.REACT_APP_JWT_SECRET);
			this.setState({
				session,
			});
		}
		// Fetch Profile image for header
		let path =
			'organizations/' +
			this.props.organization_uuid +
			'/users/' +
			this.props.user.uuid +
			'/profile/';
		axios({
			method: 'POST',
			url: '/api/v1/file-service/single',
			data: { path },
			responseType: 'arraybuffer',
		})
			.then(response => {
				if (response.status === 200) {
					const blob = new Blob([response.data], {
						type: 'application/octet-stream',
					});
					const urlObj = window.URL.createObjectURL(blob);
					this.setState({ profile_img_url: urlObj });
					this.props.updateImageURL(urlObj)
				}
			})
			.catch(err => {
				console.log(err, 'err');
			});
	};

	toggleDropdown = () => {
		this.setState({ dropdown: !this.state.dropdown });
	};

	reroute = route => {
		this.toggleDropdown();

		this.props.history.push(route);
	};

	returnToOrigialUser = () => {
		sessionStorage.removeItem('proxyLogin');
		this.props.history.push('/');
		window.location.reload();
	};
	componentDidUpdate(prevProps, prevState) {
		if (this.props.isImageChanged) {
			let path =
				'organizations/' +
				this.props.organization_uuid +
				'/users/' +
				this.props.user.uuid +
				'/profile/';
			axios({
				method: 'POST',
				url: '/api/v1/file-service/single',
				data: { path },
				responseType: 'arraybuffer',
			})
				.then(response => {
					if (response.status === 200) {
						const blob = new Blob([response.data], {
							type: 'application/octet-stream',
						});
						const urlObj = window.URL.createObjectURL(blob);
						this.setState({ profile_img_url: urlObj });
						this.props.updateImageURL(urlObj);
					}
				})
				.catch(err => {
					console.log(err, 'err');
				});
			this.props.updateImage();
		}
		if (prevProps.user !== this.props.user && this.props.user !== '') {
			if (this.props.user.tenant_id) {
				sessionStorage.setItem(
					'tenant_id',
					jwt.encode(this.props.user.tenant_id, process.env.REACT_APP_JWT_SECRET) || '',
				);
			}
		}
	}

	render() {
		let { authenticated, user, organization, imageURL } = this.props;

		let userDetails = user ? (
			<span
				id="custom-user-profile"
				onClick={this.toggleDropdown}
				className="nav-link nav-user arrow-none mr-0 btn"
			>
				<span id="custom-header-profile-img" className="account-user-avatar">
					<img
						src={
							imageURL
								? imageURL
								: 'assets/images/users/user-avatar.png'
						}
						alt="profile"
						className="rounded-circle"
					/>
				</span>
				<span>
					<span className="account-user-name">
						{user.preferred_name
							? user.preferred_name
							: user.first_name + (' ' + user.last_name)}
					</span>
					<span className="account-position">
						{organization.length > 15
							? organization.slice(0, 14) + '...'
							: organization}
					</span>
				</span>
			</span>
		) : (
			<span />
		);

		return (
			<div className="content">
				<div id="custom-header" className="navbar-custom">
					{this.state.session.originalUserUuid && (
						<button onClick={this.returnToOrigialUser} className="btn btn-primary mt-1">
							Return to Superadmin
						</button>
					)}

					{this.props.showDemoFlag && (
						<div
							className="d-inline-flex sjustify-content-center align-items-center h-100"
							style={{
								minHeight: '50px',
								maxWidth: this.state.session.originalUserUuid ? '40%' : 'auto',
							}}
						>
							<a
								rel="noopener noreferrer"
								href="https://doneforyou.com/demo"
								target="_blank"
								className="text-center p-0 m-0"
							>
							</a>
						</div>
					)}

					<ul className="list-unstyled topbar-right-menu float-right mb-0">
						<li
							id="dropdownIndex"
							className={`dropdown notification-list  ${this.state.dropdown ? 'show' : ''
								}`}
						>
							{authenticated ? (
								<React.Fragment>
									{userDetails}
									<div
										className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown"
										style={{ display: 'block', margin: '-14px' }}
									>
										<div className="dropdown-header noti-title">
											<h6 className="text-overflow m-0">
												Welcome{' '}
												{this.props.user.preferred_name ||
													this.props.user.first_name}
												!
											</h6>
										</div>

										<span
											onClick={() => this.reroute('/settings')}
											className="dropdown-item notify-item btn"
											style={{ textAlign: 'left' }}
										>
											<i className="mdi mdi-account-circle" />
											<span>My Account</span>
										</span>

										<span
											onClick={() => this.reroute('/settings/changePassword')}
											className="dropdown-item notify-item btn"
											style={{ textAlign: 'left' }}
										>
											<i className="mdi mdi-key" />
											<span>Change Password</span>
										</span>

										<span
											onClick={this.props.logout}
											className="dropdown-item notify-item btn"
											style={{ textAlign: 'left' }}
										>
											<i className="mdi mdi-logout" />
											<span>Logout</span>
										</span>
									</div>
								</React.Fragment>
							) : (
								<span
									id="custom-user-login"
									className="nav-link nav-user arrow-none"
								>
									<button onClick={this.props.login} className="btn btn-primary">
										Login
									</button>
								</span>
							)}
						</li>
					</ul>

					<button className="button-menu-mobile open-left disable-btn">
						<i className="mdi mdi-menu" />
					</button>

					<a href="mailto:support@doneforyou.com">
						<img
							src="http://res.cloudinary.com/heliumup/image/upload/v1527951084/help.png"
							target="new"
							className="help"
							alt="email action"
						/>
					</a>

					<a
						rel="noopener noreferrer"
						href="https://academy.doneforyou.com/"
						target="_blank"
					>
						<img
							src="https://res.cloudinary.com/heliumup/image/upload/v1548883280/icon_academy.png"
							className="academy"
							alt="academy training courses"
						/>
					</a>
				</div>
				<div className="container-fluid" />
			</div>
		);
	}
}

const mapDispatchToProps = {
	updateImage,
	updateImageURL
};

function mapStateToProps(state) {
	return {
		user: state.GlobalReducer.user,
		organization: state.GlobalReducer.organization.name,
		organization_uuid: state.GlobalReducer.organization.organization_uuid,
		showDemoFlag: state.GlobalReducer.showDemoFlag,
		isImageChanged: state.GlobalReducer.isImageChanged,
		imageURL: state.GlobalReducer.imageURL
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
