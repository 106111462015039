import {
	SAVE_CAMPAIGN,
	CAMPAIGN_ID,
	ALL_CAMPAIGNS,
	GET_ONE_CAMPAIGN,
	HIDE_LOADING,
	DELETE_COMPAIGN,
} from '../actions/allActions';

const initialSate = {
	campaignArray: [],
	campaign_id: '',
	campaignsArray: [],
	loadingArray: false,
	oneCampaignArray: '',
	no_campaign_message: '',
};
const campaigsVSLReducer = (state = initialSate, action) => {
	switch (action.type) {
		case SAVE_CAMPAIGN: {
			return {
				...state,
				campaignArray: action.response,
			};
		}
		case CAMPAIGN_ID: {
			return {
				...state,
				campaign_id: action.response,
			};
		}
		case ALL_CAMPAIGNS: {
			return {
				...state,
				campaignsArray: action.response,
				no_campaign_message: action.messsage,
			};
		}
		case GET_ONE_CAMPAIGN: {
			return {
				...state,
				oneCampaignArray: action.response,
			};
		}
		case DELETE_COMPAIGN: {
			var campaignsArray = state.campaignsArray.filter(
				item => item.id !== action.campaign_id,
			);
			return {
				...state,
				campaignsArray,
			};
		}
		case HIDE_LOADING: {
			return {
				...state,
				loadingArray: action.response,
			};
		}
		default:
			return state;
	}
};
export default campaigsVSLReducer;
