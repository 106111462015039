export const SAVE_CAMPAIGN = 'SAVE_CAMPAIGN';
export const SAVE_QUESTION_FORM = 'SAVE_QUESTION_FORM';
export const CREATE_PPTX = 'CREATE_PPTX';
export const SAVE_QUESTIONFROM2 = 'SAVE_QUESTIONFROM2';
export const SAVE_QUESTIONFROM3 = 'SAVE_QUESTIONFROM3';
export const SAVE_QUESTIONFROM4 = 'SAVE_QUESTIONFROM4';
export const SAVE_QUESTIONFROM5 = 'SAVE_QUESTIONFROM5';
export const SAVE_QUESTIONFROM6 = 'SAVE_QUESTIONFROM6';
export const ALL_CAMPAIGNS = 'ALL_CAMPAIGNS';
export const DELETE_COMPAIGN = 'DELETE_COMPAIGN';
export const GET_STEPS = 'GET_STEPS';
export const HIDE_LOADING = 'HIDE_LOADING';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const GET_ONE_CAMPAIGN = 'GET_ONE_CAMPAIGN';
export const GET_QUESTION_FORM = 'GET_QUESTION_FORM';
export const UPDATE_QUESTION_FORM1 = 'UPDATE_QUESTION_FORM1';
export const STEPS_QUESTIONFROM3 = 'STEPS_QUESTIONFROM3';
export const GET_QUESTIONFROM5 = 'GET_QUESTIONFROM5';
export const GET_QUESTIONFROM6 = 'GET_QUESTIONFROM6';
export const CAMPAIGN_ID = 'CAMPAIGN_ID';
export const GET_CAMPAIGN_NAME = 'GET_CAMPAIGN_NAME';
