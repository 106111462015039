import axios from 'axios';
import history from '../../utils/history';
import { updateOrganizationalData } from '../GlobalReducer';

// ==================================================================================== //
// ===================================== ACTIONS ====================================== //
// ==================================================================================== //

const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
const DEACTIVATE_ORGANIZATION = 'DEACTIVATE_ORGANIZATION';

const GET_ORGANIZATION_FIELDS = 'GET_ORGANIZATION_FIELDS';
const UPDATE_ORGANIZATION_FIELDS = 'UPDATE_ORGANIZATION_FIELDS';

// ============================================================ //
// =================== GET ORGANIZATIONS ====================== //
// ============================================================ //

export function getOrganizations() {
	return (dispatch, getState) => {
		let state = getState();

		let { organizations } = state.SystemsReducer;

		if (organizations.length) {
			// already fetched organizations from store
			dispatch({
				type: GET_ORGANIZATIONS,
				payload: null,
			});
		} else {
			// need to fetch organizations
			const fetchOrganizations = axios({
				url: '/api/v1/organizations/all',
				method: 'GET',
			});
			dispatch({
				type: GET_ORGANIZATIONS,
				payload: fetchOrganizations,
			});
		}
	};
}

// ============================================================ //
// =================== FETCH ORGANIZATION ===================== //
// ============================================================ //

export function fetchOrganization(uuid) {
	return (dispatch, getState) => {
		let state = getState();

		let { organizations } = state.SystemsReducer;

		// check to see if organizations array contains full details for specific organization uuid
		organizations = organizations.filter(org => org.uuid === uuid);

		if (organizations.length && !organizations[0].hasFetchedDetails) {
			// need to fetch organizations
			const fetchOrganization = axios({
				url: '/api/v1/organizations/by-uuid/' + uuid,
				method: 'GET',
			});

			dispatch({
				type: FETCH_ORGANIZATION,
				payload: fetchOrganization,
			});
		} else {
			// have already fetched organization details
			dispatch({
				type: FETCH_ORGANIZATION,
				payload: null,
			});
		}
	};
}

// ============================================================ //
// =================== UPDATE ORGANIZATION ==================== //
// ============================================================ //

export function updateOrganization(organization, address) {
	return dispatch => {
		const updateOrganization = axios({
			url: '/api/v1/organizations/update',
			method: 'PUT',
			data: {
				organization,
				address,
			},
		});

		dispatch({
			type: UPDATE_ORGANIZATION,
			payload: updateOrganization,
		});
	};
}

// ============================================================ //
// =================== CREATE ORGANIZATION ==================== //
// ============================================================ //

export function createOrganization(organization, address) {
	return dispatch => {
		const createOrganization = axios({
			url: '/api/v1/organizations/create',
			method: 'POST',
			data: {
				organization,
				address,
			},
		});

		dispatch({
			type: CREATE_ORGANIZATION,
			payload: createOrganization,
		}).then(res => {
			return history.push('/system/organizations/' + res.value.data.values.organization.uuid);
		});
	};
}

// ============================================================ //
// ================ DEACTIVATE ORGANIZATION =================== //
// ============================================================ //

export function deactivateOrganization(uuid) {
	return dispatch => {
		const deactivateOrganization = axios({
			url: '/api/v1/organizations/by-uuid/' + uuid,
			method: 'DELETE',
		});

		dispatch({
			type: DEACTIVATE_ORGANIZATION,
			payload: deactivateOrganization,
		}).then(() => {
			return history.push('/system/organizations/');
		});
	};
}
// ============================================================ //
// ================ GET ORG FIELDS ============================ //
// ============================================================ //

export function getOrganizationFields() {
	return (dispatch, getState) => {
		let state = getState();

		let { organizationFields } = state.SystemsReducer;

		if (organizationFields.length) {
			// already fetched organizations from store
			dispatch({
				type: GET_ORGANIZATION_FIELDS,
				payload: null,
			});
		} else {
			// need to fetch organizations
			const fetchOrganizationFields = axios({
				url: '/api/v1/organization-fields',
				method: 'GET',
			});
			dispatch({
				type: GET_ORGANIZATION_FIELDS,
				payload: fetchOrganizationFields,
			});
		}
	};
}

// ============================================================ //
// =================== UPDATE ORG FIELD ======================= //
// ============================================================ //
export function updateOrganizationFields(body) {
	return dispatch => {
		const updatePackages = axios({
			url: '/api/v1/org-fields/create',
			method: 'POST',
			data: body,
		});
		dispatch({
			type: UPDATE_ORGANIZATION_FIELDS,
			payload: updatePackages,
		}).then(result => {
			const { values } = result.value.data;
			dispatch(updateOrganizationalData(values, 'custom'));
		});
	};
}
