import React from 'react';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

class SideBar extends React.Component {
	state = { currentLocation: '', applications: [] };

	static getDerivedStateFromProps(props, state) {
		let { applications, location } = props;
		let currentLocation =
			location.pathname && ~location.pathname.indexOf('/')
				? location.pathname.split('/')[1]
				: '';
		return {
			currentLocation,
			applications,
		};
	}

	render() {
		let currentLocation = '/' + this.state.currentLocation; // adds the / prefixing back to the route
		if (this.state.applications) {
			const mapApplications = this.state.applications
				.sort((a, b) => {
					if (a.id > b.id) return 1;
					if (a.id < b.id) return -1;
					return 0;
				})
				.map((e, i) => {
					// make sure all the applications are shown, and prevent axis from crashing
					// if the app doesn't have any routes
					if (
						e.nav_routes &&
						currentLocation.includes(e.nav_routes.baseurl) &&
						e.route_url !== '/settings'
					) {
						return (
							<div key={i} className="imgContainer imgContainerActive">
								<img className="img" src={e.img_url_light} alt={e.name || ''} />
							</div>
						);
					} else {
						if (e.id !== 18 && e.id !== 23)
							return (
								<Link to={e.route_url} key={i} className="imgContainer">
									<img className="img" src={e.img_url_dark} alt={e.name || ''} />
								</Link>
							);
					}
					return null;
				});

			return (
				<div className="SideBar">
					{mapApplications}
					<div className="fillSideBar" />
				</div>
			);
		} else {
			let nav = document.getElementById('custom-navbar');
			if (nav) {
				nav.style.width = '260px !important';
				nav.style.left = '0 !important';
			}
			return (
				<div className="SideBar">
					<a
						className="imgContainer"
						href="#/"
						style={{ border: '1px solid lightgrey' }}
						data-toggle="tooltip"
						data-placement="top"
						title="Dashboard"
					>
						<img
							className="img"
							src="assets/images/185093_dashboard_speed_icon.png"
							alt="Dashboard"
						/>
					</a>
					<div className="fillSideBar" />
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		applications: state.GlobalReducer.applications,
	};
}

export default withRouter(connect(mapStateToProps)(SideBar));
