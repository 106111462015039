export const SAVE_CAMPAIGN = 'SAVE_CAMPAIGN';
export const ALL_CAMPAIGNS = 'ALL_CAMPAIGNS';
export const HIDE_LOADING = 'HIDE_LOADING';
export const GET_ONE_CAMPAIGN = 'GET_ONE_CAMPAIGN';
export const CAMPAIGN_ID = 'CAMPAIGN_ID';
export const GET_ALL_SLIDES_NAMES = 'GET_ALL_SLIDES_NAMES';
export const GET_ALL_SLIDE_DETAILS = 'GET_ALL_SLIDE_DETAILS';
export const SAVE_SLIDE_DETAILS = 'SAVE_SLIDE_DETAILS';
export const DELETE_COMPAIGN = 'DELETE_COMPAIGN';
export const GET_CAMPAIGN_DETAILS = 'GET_CAMPAIGN_DETAILS';
export const UPDATE_CUSTOM_SLIDE_DETAILS = 'UPDATE_CUSTOM_SLIDE_DETAILS';
export const DELETE_THIS_SLIDE = 'DELETE_THIS_SLIDE';
export const DOWNLOAD_PRESENTATION = 'DOWNLOAD_PRESENTATION';
