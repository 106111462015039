import ReactDOM from 'react-dom';
import React from 'react';
import createReactClass from 'create-react-class';
import Modal from '../components/Modal';

import axios from 'axios';

import history from '../utils/history';
// ========== INITIAL STATE ========== //
const initialState = {
	user: undefined,
	fetchingUser: false,
	registeringUser: false,
	shouldFetchUser: false,
	registerFailed: false,
	updatingUser: false,
	organization: {},
	package_info: {},
	applications: [],
	packages: [],
	organizationalData: {
		default: [],
		custom: [],
		userPreferences: [],
	},
	logOutUser: false,
	saving: false,
	savingError: false,
	analytics: { site: null },
	showDemoFlag: false,
	isImageChanged: false,
	isNewUser: true,
	imageURL: ''
};

// ========== ACTION TYPES ========== //
const HANDLE_ANALYTICS_ACTIVE_SITE = 'HANDLE_ANALYTICS_ACTIVE_SITE';
const GET_USER = 'GET_USER';
const REGISTER_USER = 'REGISTER_USER';
const UPDATE_GLOBAL_USER = 'UPDATE_GLOBAL_USER';
const LOG_ACTIVITY = 'LOG_ACTIVITY';
const HANDLE_ROUTE = 'HANDLE_ROUTE';
const UPDATE_ORGANIZATIONAL_DATA = 'UPDATE_ORGANIZATIONAL_DATA';
const TOGGLE_CONFIRMATION = 'TOGGLE_CONFIRMATION';
const SET_DEMO_FLAG = 'SET_DEMO_FLAG';
const ACTIVATE_ORGANIZATION_ADD_ON = 'ACTIVATE_ORGANIZATION_ADD_ON';
const DEACTIVATE_ORGANIZATION_ADD_ON = 'DEACTIVATE_ORGANIZATION_ADD_ON';
const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS';

const GENERATE_KEY = 'GENERATE_KEY';
const DELETE_KEY = 'DELETE_KEY';
const NEW_USER = 'NEW_USER';
const IMAGE_CHANGED = 'IMAGE_CHANGED';
const IMAGE_URL = 'IMAGE_URL';

// ========== REDUCER ========== //

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GET_USER + '_PENDING':
			return { ...state, fetchingUser: true, loadingArray: { loading: true } };

		case GET_USER + '_FULFILLED':
			let {
				applications,
				packages,
				user,
				organization,
				organizationalData,
			} = action.payload.data.values;
			let { applications: apps, ...package_info } = applications;
			// The user has logged in
			logActivity(user.id, 'Logged In');

			if (!applications.length) {
				let nav = document.getElementById('custom-navbar');
				if (nav) {
					nav.style.width = '260px !important';
					nav.style.left = '0 !important';
				}
			}

			return {
				...state,
				user: user,
				organization: organization,
				applications: apps,
				packages,
				package_info: package_info,
				organizationalData: organizationalData,
				fetchingUser: false,
			};

		case GET_USER + '_REJECTED':
			console.log('action.payload', JSON.stringify(action.payload));
			return { ...state, fetchingUser: false, user: null, logOutUser: true };

		case NEW_USER:
			return { isNewUser: false };

		case REGISTER_USER + '_PENDING':
			return { ...state, registeringUser: true, registerFailed: false };
		case REGISTER_USER + '_FULFILLED':
			history.push('/crm');
			return { ...state, registeringUser: false, shouldFetchUser: true };
		case REGISTER_USER + '_REJECTED':
			history.push('/crm');
			return { ...state, shouldFetchUser: true, registeringUser: false };

		case UPDATE_GLOBAL_USER + '_PENDING':
			return { ...state, updatingUser: true };

		case UPDATE_GLOBAL_USER + '_FULFILLED':
			let newUser = {
				...action.payload.data.values.updatedUser,
				...action.payload.data.values.address,
			};
			let newOrganization = {
				organization_id: action.payload.data.values.updatedOrganization.id,
				organization_uuid: action.payload.data.values.updatedOrganization.uuid,
				package_id: action.payload.data.values.updatedOrganization.package_id,
				website: action.payload.data.values.updatedOrganization.website,
				name: action.payload.data.values.updatedOrganization.name,
			};
			return { ...state, user: newUser, organization: newOrganization, updatingUser: false };

		case HANDLE_ROUTE:
			// get variables passed from invoker, and applications list
			let { route, status } = action.payload;
			let { applications: enable_route_applications } = state;

			// split routes and remove empty string at beginning of array
			let split_route = route.split('/');
			split_route.shift();

			// if there are no more levels to go, end function here
			if (!split_route.length) {
				return state;
			}

			// map over applications to find the one that contains the specified route
			enable_route_applications = enable_route_applications.map(application => {
				// return null if the application has no routes
				if (!application.nav_routes) {
					return application;
				}

				if (application.nav_routes.baseurl.includes(split_route[0])) {
					// remove first item from route path
					split_route.shift();

					// if there are no more levels to go, end function here
					if (!split_route.length) {
						application.active = status;
						return application;
					}

					// if status is true, make sure the application.active is also true
					if (status) {
						application.active = status;
					}

					// map over the routes
					application.nav_routes.routes = application.nav_routes.routes.map(route => {
						// find route with the next item in the path
						if (route.route.includes(split_route[0])) {
							// remove second item from route path
							split_route.shift();

							// if there are no more levels to go, end function here
							if (!split_route.length) {
								console.log(route, 'route');
								route.active = status;
								return route;
							}

							// if status is true, make sure the route.active is also true
							if (status) {
								route.active = status;
							}

							// map over subroutes and find the next item in the path
							route.subroutes = route.subroutes.map(subroute => {
								// check final subroutes, and set the status of the route defined
								if (subroute.route.includes(split_route[0])) {
									subroute.active = status;
								}
								return subroute;
							});
						}

						return route;
					});
				}
				return application;
			});

			return { ...state, applications: enable_route_applications };
		case UPDATE_ORGANIZATIONAL_DATA:
			return { ...state, organizationalData: action.payload };
		case TOGGLE_CONFIRMATION:
			const { saving, savingError } = action.payload;
			return { ...state, saving, savingError };
		case HANDLE_ANALYTICS_ACTIVE_SITE:
			var updatedAnalytics = { ...state.analytics };
			updatedAnalytics.site = action.payload;
			return { ...state, analytics: updatedAnalytics };

		case ACTIVATE_ORGANIZATION_ADD_ON + '_PENDING':
			return { ...state, saving: true };
		case ACTIVATE_ORGANIZATION_ADD_ON + '_FULFILLED':
			let newApplications = [
				...state.applications,
				...action.payload.data.values.application_detail,
			];
			return { ...state, applications: newApplications, saving: false };
		case ACTIVATE_ORGANIZATION_ADD_ON + '_REJECTED':
			return { ...state, saving: false };

		//=============================DEACTIVATE ORGANIZATION==============================//
		case DEACTIVATE_ORGANIZATION_ADD_ON + '_PENDING':
			return { ...state, saving: true };
		case DEACTIVATE_ORGANIZATION_ADD_ON + '_FULFILLED':
			newApplications = [
				...state.applications,
				...action.payload.data.values.application_detail,
			];
			return { ...state, applications: newApplications, saving: false };
		case DEACTIVATE_ORGANIZATION_ADD_ON + '_REJECTED':
			return { ...state, saving: false };
		//=============================DEACTIVATE ORGANIZATION==============================//
		// ==================================================================================== //
		// ============================= GET ACCOUNT DETAILS ================================== //
		// ==================================================================================== //
		case GET_ACCOUNT_DETAILS + '_PENDING':
			return { ...state, fetchingAccountDetails: true };
		case GET_ACCOUNT_DETAILS + '_FULFILLED':
			let getAccountDetailsOrganization = state.organization;

			// if the organization has not previously fetched details
			if (!getAccountDetailsOrganization.hasFetchedDetails) {
				getAccountDetailsOrganization = {
					...getAccountDetailsOrganization,
					...action.payload.data.values.organization,
					hasFetchedDetails: true,
				};
			}

			return {
				...state,
				organization: getAccountDetailsOrganization,
				fetchingAccountDetails: false,
			};
		case GET_ACCOUNT_DETAILS + '_REJECTED':
			return { ...state, fetchingAccountDetails: false };

		// ==================================================================================== //
		// ============================= GENERATE KEY ========================================= //
		// ==================================================================================== //
		case GENERATE_KEY + '_PENDING':
			return { ...state, generatingKey: true };
		case GENERATE_KEY + '_FULFILLED':
			let api_keys_generate = state.organization.api_keys;

			let api_key = { ...action.payload.data.values };

			delete api_key.msg;
			delete api_key.unhashed_key;
			delete api_key.total_keys;

			api_keys_generate.push(api_key);

			return {
				...state,
				organization: {
					...state.organization,
					api_keys: api_keys_generate,
				},
				generatingKey: false,
			};
		case GENERATE_KEY + '_REJECTED':
			return { ...state, generatingKey: false };

		// ==================================================================================== //
		// ============================= GENERATE KEY ========================================= //
		// ==================================================================================== //
		case DELETE_KEY + '_PENDING':
			return { ...state, generatingKey: true };
		case DELETE_KEY + '_FULFILLED':
			let api_keys_delete = state.organization.api_keys;
			let api_key_delete = action.payload.data.values.key;

			api_keys_delete = api_keys_delete.filter(key => {
				if (key.friendly_name === api_key_delete.friendly_name) {
					return false;
				}
				return true;
			});

			return {
				...state,
				organization: {
					...state.organization,
					api_keys: api_keys_delete,
				},
				generatingKey: false,
			};
		case DELETE_KEY + '_REJECTED':
			return { ...state, generatingKey: false };

		// ============================== SET DEMO FLAG ============================== //
		case SET_DEMO_FLAG:
			return { ...state, showDemoFlag: action.payload };
		case IMAGE_CHANGED:
			return { ...state, isImageChanged: !state.isImageChanged };
		case IMAGE_URL:
			return { ...state, imageURL: action.payload || '' }
		default:
			return state;
	}
}

// ========== DISPATCH ACTIONS ========== //

export function handleAnalyticsActiveSite(site) {
	return {
		type: HANDLE_ANALYTICS_ACTIVE_SITE,
		payload: site,
	};
}

export function registerUser(user, user_metadata) {
	return axios.post(`/api/v1/hooks/pre-register`, {
		email: user?.name,
		first_name: user_metadata?.first_name,
		last_name: user_metadata?.last_name,
		organization_name: user?.name,
	});
	// register.user = user;
	// return {
	// 	type: REGISTER_USER,
	// 	payload: register,
	// };
}

export function getUser(user) {
	let axisUser = axios.post(`api/v1/get-user`, {
		email: user?.name,
		user_id: user?.sub,
	});
	// dispatch payload
	return {
		type: GET_USER,
		payload: axisUser,
	};
}

export function updateUser(updateUser, address) {
	return dispatch => {
		const usr = axios({
			url: '/api/v1/global-user/update',
			method: 'PUT',
			data: {
				user: updateUser,
				address: address,
			},
		});

		return dispatch({
			type: UPDATE_GLOBAL_USER,
			payload: usr,
		});
	};
}

export function logActivity(user_id, activity) {
	/**
	 * @param {String->UUID} user_id the id of the user invoking the function
	 * @param {String} activity a string describing the activity (logged to database)
	 * @example activity = 'Campaign Created'
	 */

	const log = axios.post('/api/v1/log-activity', { user_id, activity });

	return {
		type: LOG_ACTIVITY,
		payload: log,
	};
}

export function updateOrganizationalData(array, arrayToUpdate) {
	/**
	 * @param {String} arrayToUpdate the string of the title of the array to be updated in organizational data
	 * @param {Array} array the array of data to be added
	 */
	let arrayCopy = [...array];
	if (!arrayToUpdate || typeof arrayToUpdate !== 'string')
		throw new Error('Must include the name of the array to be updated as a string');
	if (arrayToUpdate === 'custom') arrayCopy = arrayCopy.filter(e => e.entity === 'contact');
	return (dispatch, getState) => {
		let state = getState();
		let { organizationalData } = state.GlobalReducer;
		organizationalData[arrayToUpdate] = arrayCopy;
		dispatch({
			type: UPDATE_ORGANIZATIONAL_DATA,
			payload: organizationalData,
		});
	};
}

export function activateOrganizationAddon(app_id, organization_uuid, user) {
	return dispatch => {
		const updatedAddons = axios.post('/api/v1/organization/addon/' + organization_uuid, {
			app_id,
			user,
		});
		return dispatch({
			type: ACTIVATE_ORGANIZATION_ADD_ON,
			payload: updatedAddons,
		});
	};
}

export function deactivateOrganizationAddon(app_id, organization_uuid) {
	return dispatch => {
		const updatedAddoffs = axios.post('/api/v1/organization/addoff/' + organization_uuid, {
			app_id,
		});
		return dispatch({
			type: DEACTIVATE_ORGANIZATION_ADD_ON,
			payload: updatedAddoffs,
		});
	};
}

export function handleRouteStatus(route, status) {
	/**
	 * @param {String} route the route that should be either enabled or disabled.
	 * It is patterned like so:
	 *
	 * '/statly/visitors/technology', where the first section is the application route,
	 * the second section is the main route, and the third (optional) section is a subroute.
	 *
	 * @param {Boolean} status if true, the route should be enabled, if false the route is disabled.
	 * @description change the status of a specific route. The route status can be either set to
	 * true or false. false is disabled, true is enabled.
	 */

	return {
		type: HANDLE_ROUTE,
		payload: {
			route,
			status,
		},
	};
}

export function toggleConfirmation(saving, savingError) {
	return {
		type: TOGGLE_CONFIRMATION,
		payload: {
			saving,
			savingError,
		},
	};
}

export function setDemoFlag(state) {
	return {
		type: SET_DEMO_FLAG,
		payload: state,
	};
}

export function newUser() {
	return {
		type: SET_DEMO_FLAG,
		payload: {},
	};
}

// ============================================================ //
// ================== GET ACCOUNT DETAILS ===================== //
// ============================================================ //
export function getAccountDetails() {
	return dispatch => {
		const accountDetails = axios({
			url: '/api/v1/organizations/get-account-details',
			method: 'GET',
		});

		dispatch({
			type: GET_ACCOUNT_DETAILS,
			payload: accountDetails,
		});
	};
}

// ============================================================ //
// ====================== GENERATE KEY ======================== //
// ============================================================ //
export function generateKey() {
	return dispatch => {
		const generateApiKey = axios({
			url: '/api/v1/keys/generate',
			method: 'POST',
		});

		dispatch({
			type: GENERATE_KEY,
			payload: generateApiKey,
		}).then(resp => {
			let key = resp.action.payload.data.values;
			let name = key.friendly_name.replace(/_/g, ' ');

			let portal = document.getElementById('portal');
			console.log(portal, 'portal');
			let RenderedModal = createReactClass({
				getInitialState: function () {
					return {
						open: true,
					};
				},
				render: function () {
					return this.state.open ? (
						<Modal
							state={this.state.open}
							close={() => this.setState({ open: false })}
							readonly={true}
							title="API Key Created!"
							save={() => { }}
							saveText="Save"
						>
							<p>
								<strong>Name: </strong>
								{name}
							</p>
							<p>
								<strong>Key: </strong>
								{key.unhashed_key}
							</p>
							<br />
							<p>
								<strong>NOTE: </strong>
								For security reasons, we cannot show you this key again. Please save
								the name and key in a secure location.
							</p>
						</Modal>
					) : null;
				},
			});

			ReactDOM.render(<RenderedModal />, portal);
		});
	};
}

// ============================================================ //
// ====================== GENERATE KEY ======================== //
// ============================================================ //
export function deleteKey(friendly_name) {
	return dispatch => {
		const deleteApiKey = axios({
			url: '/api/v1/keys/delete',
			method: 'DELETE',
			data: {
				friendly_name,
			},
		});

		dispatch({
			type: DELETE_KEY,
			payload: deleteApiKey,
		});
	};
}

export function updateImage() {
	return {
		type: IMAGE_CHANGED,
		payload: {},
	};
};

export function updateImageURL(url) {
	return dispatch => {
		dispatch({
			type: IMAGE_URL,
			payload: url
		})
	}
};
