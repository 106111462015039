import {
	ALL_CAMPAIGNS,
	DELETE_COMPAIGN,
	UPDATE_CAMPAIGN,
	GET_ONE_CAMPAIGN,
	HIDE_LOADING,
	GET_CAMPAIGN_NAME,
} from '../actions/webinarActions/allActions';

const initialSate = {
	update_campaignsArray: [],
	campaignsArray: [],
	loadingArray: false,
	oneCampaignArray: '',
	no_campaign_message: '',
	campaign_name: '',
};
const allCompaignsReducer = (state = initialSate, action) => {
	switch (action.type) {
		case ALL_CAMPAIGNS: {
			return {
				...state,
				campaignsArray: action.response,
				no_campaign_message: action.messsage,
			};
		}
		case UPDATE_CAMPAIGN: {
			return {
				...state,
				update_campaignsArray: action.response,
			};
		}
		case GET_ONE_CAMPAIGN: {
			return {
				...state,
				oneCampaignArray: action.response,
			};
		}
		case DELETE_COMPAIGN: {
			var campaignsArray = state.campaignsArray.filter(
				item => item.id !== action.campaign_id,
			);
			return {
				...state,
				campaignsArray,
			};
		}
		case HIDE_LOADING: {
			return {
				...state,
				loadingArray: action.response,
			};
		}
		case GET_CAMPAIGN_NAME: {
			return {
				...state,
				campaign_name: action.response.result,
			};
		}
		default:
			return state;
	}
};
export default allCompaignsReducer;
