import axios from 'axios';

const GET_SYSTEMS_DASHBOARD = 'GET_SYSTEMS_DASHBOARD';
const GET_SYSTEMS_USER_DASHBOARD = 'GET_SYSTEMS_USER_DASHBOARD';

export function getSystemsDashInfo() {
	return (dispatch, getState) => {
		let state = getState();

		let { dashboardInfo } = state.SystemsReducer;

		if (dashboardInfo.length) {
			// already fetched organizations from store
			dispatch({
				type: GET_SYSTEMS_DASHBOARD,
				payload: null,
			});
		} else {
			// need to fetch organizations
			const fetchDashInfo = axios({
				url: '/api/v1/systems-dashboard',
				method: 'GET',
			});
			dispatch({
				type: GET_SYSTEMS_DASHBOARD,
				payload: fetchDashInfo,
			});
		}
	};
}

export function getSystemsUserDashInfo() {
	return (dispatch, getState) => {
		let state = getState();
		let { dashboardInfo } = state.SystemsReducer;
		let { organization_id } = state.GlobalReducer.organization;

		if (dashboardInfo.length) {
			dispatch({
				type: GET_SYSTEMS_USER_DASHBOARD,
				payload: null,
			});
		} else {
			const fetchDashInfo = axios({
				url: '/api/v1/systems/dashboard/' + organization_id,
				method: 'GET',
			});
			dispatch({
				type: GET_SYSTEMS_USER_DASHBOARD,
				payload: fetchDashInfo,
			});
		}
	};
}
