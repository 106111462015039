import axios from 'axios';

import history from '../../utils/history';

// ==================================================================================== //
// ===================================== ACTIONS ====================================== //
// ==================================================================================== //

const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
const GET_CAMPAIGN_DETAILS = 'GET_CAMPAIGN_DETAILS';
const SAVE_CAMPAIGN_AUTOMATION = 'SAVE_CAMPAIGN_AUTOMATION';

const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';

// ============================================================ //
// ===================== GET CAMPAIGNS ======================== //
// ============================================================ //

export function getCampaigns() {
	return (dispatch, getState) => {
		let state = getState();

		let { campaigns } = state.CRMReducer;

		// if (campaigns.length) {
		// 	dispatch({
		// 		type: GET_CAMPAIGNS,
		// 		payload: null,
		// 	});
		// } else {
		const fetch_campaigns = axios.get('/api/v1/campaigns/all');
		dispatch({
			type: GET_CAMPAIGNS,
			payload: fetch_campaigns,
		});
		// }
	};
}

// ============================================================ //
// ================= GET CAMPAIGN DETAILS ===================== //
// ============================================================ //

export function getCampaignDetails(campaign_uuid) {
	return (dispatch, getState) => {
		let state = getState();
		// *********** Code Commented for fetching campaing detail un conditionaly. *********** //
		// // check if redux state already has Campaign details based on uuid
		// let campaign_details = state.CRMReducer.campaigns.filter(
		// 	campaign => campaign.uuid === campaign_uuid && campaign.hasFetchedDetails,
		// );

		// // if have already fetched details, do not fetch again
		// if (campaign_details.length) {
		// 	dispatch({
		// 		type: GET_CAMPAIGN_DETAILS,
		// 		payload: null,
		// 	});
		// } else {
		// *********************************************************************** //
		// check if user should pull email templates
		let pull_emails = state.CRMReducer.emailTemplates.length === 0;

		// check if user should pull forms
		let pull_forms = state.CRMReducer.forms.length === 0;

		// check if user should pull tags
		let pull_tags = state.CRMReducer.tags.length === 0;

		const campaign = axios.get(
			`/api/v1/campaigns/by-uuid/${campaign_uuid}?emails=${pull_emails}&forms=${pull_forms}&tags=${pull_tags}`,
		);

		dispatch({
			type: GET_CAMPAIGN_DETAILS,
			payload: campaign,
		});
		// }
	};
}

// ============================================================ //
// ============== UPDATE CAMPAIGN AUTOMATION ================== //
// ============================================================ //

export function saveCampaignAutomation(campaign_uuid, automations, entrypoints) {
	const updated_campaign = axios.put(`/api/v1/campaigns/update-automation/${campaign_uuid}`, {
		automations,
		entrypoints,
	});

	return {
		type: SAVE_CAMPAIGN_AUTOMATION,
		payload: updated_campaign,
	};
}

// ============================================================ //
// ================ CREATE CAMPAIGN DETAILS =================== //
// ============================================================ //

export function createCampaign(title, description) {
	return dispatch => {
		const new_campaign = axios.post(`/api/v1/campaigns/new`, {
			title,
			description,
		});

		dispatch({
			type: CREATE_CAMPAIGN,
			payload: new_campaign,
		}).then(result => {
			// redirect to the new campaigns page
			let { uuid } = result.value.data.values.campaign;

			return history.push(`/crm/campaigns/${uuid}`);
		});
	};
}

// ============================================================ //
// ================ UPDATE CAMPAIGN DETAILS =================== //
// ============================================================ //

export function updateCampaign(campaign_uuid, title, description) {
	const updated_campaign = axios.put(`/api/v1/campaigns/update-details/${campaign_uuid}`, {
		title,
		description,
	});

	return {
		type: UPDATE_CAMPAIGN,
		payload: updated_campaign,
	};
}

export function deleteCampaign(campaign_uuid, campaign_id) {
	const delete_campaign = axios.delete(`/api/v1/campaigns`, {
		data: { campaign_uuid, campaign_id },
	});

	return {
		type: DELETE_CAMPAIGN,
		payload: delete_campaign,
	};
}
