import ReactDOM from 'react-dom';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param state whether or not the modal is displaying
 * @param close close the modal
 * @param closeText the text to display on the close button
 * @param save save the information in the modal
 * @param saveText the text to display on the save button
 * @param readonly if included, will remove save button
 * @param title text on top of modal.
 */

class Modal extends React.Component {
	render() {
		return this.props.state
			? ReactDOM.createPortal(
					<React.Fragment>
						<div
							style={{ zIndex: '10000' }}
							className={`custom-modal-backdrop modal-backdrop ${
								this.props.state ? 'show' : 'hide'
							}`}
						/>
						<div
							style={{ zIndex: '10000' }}
							className={`modal fade in ${this.props.state ? 'show' : 'hide'}`}
						>
							<div
								className="modal-dialog"
								style={this.props.wide ? { maxWidth: '80vw' } : {}}
							>
								<div
									className="modal-content"
									style={this.props.wide ? { height: '90vh' } : {}}
								>
									<div className="modal-header">
										<h5 className="modal-title">{this.props.title}</h5>
										{this.props.switch && (
											<div className="d-flex">
												<p className="mr-1">{this.props.onText}</p>
												<input
													checked={this.props.checked}
													onChange={this.props.handleToggleChange}
													type="checkbox"
													name="checked"
													id="checked"
													data-switch="none"
												/>
												<label htmlFor="checked" />
												<p className="ml-1">{this.props.offText}</p>
											</div>
										)}

										{!this.props.switch && (
											<button
												type="button"
												className="close"
												aria-label="Close"
												onClick={this.props.close}
											>
												<span aria-hidden="true">&times;</span>
											</button>
										)}
									</div>
									<div className="modal-body">{this.props.children}</div>
									{!this.props.customFooter && (
										<div className="modal-footer">
											{!this.props.readonly ? (
												<button
													type="button"
													className="btn btn-primary"
													onClick={this.props.save}
												>
													{this.props.saveText || 'Save changes'}
												</button>
											) : null}
											<button
												type="button"
												className="btn btn-secondary"
												onClick={this.props.close}
											>
												{this.props.closeText || 'Close'}
											</button>
										</div>
									)}
								</div>
							</div>
						</div>
					</React.Fragment>,
					document.getElementById('portal'),
			  )
			: null;
	}
}

Modal.propTypes = {
	state: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	close: PropTypes.func.isRequired,
	save: PropTypes.func.isRequired,
	closeText: PropTypes.string,
	saveText: PropTypes.string,
	readonly: PropTypes.bool,
};

export default Modal;
