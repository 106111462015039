import { SAVE_CAMPAIGN, CAMPAIGN_ID } from '../actions/webinarActions/allActions';

const initialSate = {
	campaignArray: [],
	campaign_id: '',
};
const campaignWebReducer = (state = initialSate, action) => {
	switch (action.type) {
		case SAVE_CAMPAIGN: {
			return {
				...state,
				campaignArray: action.response,
			};
		}
		case CAMPAIGN_ID: {
			return {
				...state,
				campaign_id: action.response,
			};
		}
		default:
			return state;
	}
};
export default campaignWebReducer;
