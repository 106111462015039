export const HEADER_NAME = "HEADER_NAME";
export const UPDATE_CAMPAIGN_TITLE = "UPDATE_CAMPAIGN_TITLE";
export const GET_TEMPLATE_CAMPAIGN_NAME = "GET_TEMPLATE_CAMPAIGN_NAME";
export const GET_COMPAIGNS = "GET_COMPAIGNS";
export const DELETE_COMPAIGN = "DELETE_COMPAIGN";
export const UPDATE_COMPAIGN = "UPDATE_COMPAIGN";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const HIDE_LOADING = "HIDE_LOADING";
export const SAVE_TEMPLATE_PRE_WEBINAR = "SAVE_TEMPLATE_PRE_WEBINAR";
export const GET_TEMPLATE_LIST = "GET_TEMPLATE_LIST";
export const GET_COMPLETE_TEMPLATE_LIST = "GET_COMPLETE_TEMPLATE_LIST";
export const GET_TEMPLATES_QUESTIONS = "GET_TEMPLATES_QUESTIONS";
export const GET_EMAILS = "GET_EMAILS";
export const GET_CLICKBANK_PRODUCTS = "GET_CLICKBANK_PRODUCTS";
export const DOWNLAOD_CUSTOM_EMAILS = "DOWNLAOD_CUSTOM_EMAILS";
export const SAVE_TEMPLATE_BONDING_SEQUENCE = "SAVE_TEMPLATE_BONDING_SEQUENCE";
export const SAVE_TEMPLATE_ASCENSION_SERIES = "SAVE_TEMPLATE_ASCENSION_SERIES";
export const SAVE_TEMPLATE_COLD_PROSPECTING = "SAVE_TEMPLATE_COLD_PROSPECTING";
export const SAVE_TEMPLATE_LMS_REPORT = "SAVE_TEMPLATE_LMS_REPORT";
export const SAVE_TEMPLATE_LMS_SERIES = "SAVE_TEMPLATE_LMS_SERIES";
export const SAVE_TEMPLATE_PODCAST_OUTREACH = "SAVE_TEMPLATE_PODCAST_OUTREACH";
export const SAVE_TEMPLATE_BLOGGER_OUTREACH = "SAVE_TEMPLATE_BLOGGER_OUTREACH";
export const SAVE_TEMPLATE_WEBINAR_REPLAY = "SAVE_TEMPLATE_WEBINAR_REPLAY";
export const SAVE_TEMPLATE_VSL_PROMO = "SAVE_TEMPLATE_VSL_PROMO";
export const SAVE_TEMPLATE_SALES_LETTER_PROMO =
  "SAVE_TEMPLATE_SALES_LETTER_PROMO";
export const SAVE_TEMPLATE_WEBINAR_REPLAY_SEQ =
  "SAVE_TEMPLATE_WEBINAR_REPLAY_SEQ";
export const SAVE_TEMPLATE_SHOPPING_CART_ABANDONMENT =
  "SAVE_TEMPLATE_SHOPPING_CART_ABANDONMENT";
export const SAVE_TEMPLATE_WAITING_LIST_RELAUNCH =
  "SAVE_TEMPLATE_WAITING_LIST_RELAUNCH";
export const SAVE_TEMPLATE_PHYSICAL_PRODUCT = "SAVE_TEMPLATE_PHYSICAL_PRODUCT";
export const SAVE_POST_WABINAR_FORM = "SAVE_POST_WABINAR_FORM";
export const SAVE_DIRECT_REVENUE = "SAVE_DIRECT_REVENUE";
export const SAVE_BLOG_SEQUENCE_FORM = "SAVE_BLOG_SEQUENCE_FORM";
export const SAVE_LMS_VIDEO_FORM = "SAVE_LMS_VIDEO_FORM";
export const SAVE_STRATEGY_SESSION_FORM = "SAVE_STRATEGY_SESSION_FORM";
export const SAVE_SURVIVAL_NICHE_FORM = "SAVE_SURVIVAL_NICHE_FORM";
export const SAVE_SURVIVAL_MENS_EX_BACK = "SAVE_SURVIVAL_MENS_EX_BACK";
export const SAVE_BUSINESS_OPPORTUNITY = "SAVE_BUSINESS_OPPORTUNITY";
export const SAVE_WOMEN_HEALTH = "SAVE_WOMEN_HEALTH";
export const SAVE_BUSINESS_DEVELOPMENT = "SAVE_BUSINESS_DEVELOPMENT";
export const SAVE_GREEN_ENERGY = "SAVE_GREEN_ENERGY";
export const SAVE_MENS_DATING = "SAVE_MENS_DATING";
export const SAVE_GUITAR_NICHE = "SAVE_GUITAR_NICHE";
export const SAVE_WEDDINGS = "SAVE_WEDDINGS";
export const SAVE_DRAWING = "SAVE_DRAWING";
export const SAVE_PALEO_LIFESTYLE = "SAVE_PALEO_LIFESTYLE";
export const SAVE_GOLF = "SAVE_GOLF";
export const SAVE_MIND_BRAIN = "SAVE_MIND_BRAIN";
export const SAVE_SURVEY_FORM = "SAVE_SURVEY_FORM";
export const SAVE_RE_ANGEMENT = "SAVE_RE_ANGEMENT";
export const SAVE_PLASH_SALE_FORM = "SAVE_PLASH_SALE_FORM";
export const SAVE_WABINAR_PROMO_FORM = "SAVE_WABINAR_PROMO_FORM";
export const SAVE_FREE_SHIPPING_FORM = "SAVE_FREE_SHIPPING_FORM";
export const SAVE_WOMENEXBACK = "SAVE_WOMENEXBACK";
export const SAVE_DOG_TRAINING_FOMR = "SAVE_DOG_TRAINING_FOMR";
export const SAVE_MENFITNESS_FORM = "SAVE_MENFITNESS_FORM";
export const SAVE_FINANCE_FORM = "SAVE_FINANCE_FORM";
export const SAVE_PERSONAL_DEVELOPMENT_FORM = "SAVE_PERSONAL_DEVELOPMENT_FORM";
export const SAVE_PHOTOGRAPHY_NIECHE_FORM = "SAVE_PHOTOGRAPHY_NIECHE_FORM";
export const SAVE_REAL_ESTATE_FORM = "SAVE_REAL_ESTATE_FORM";
export const MENU = "MENU";
export const GET_ONE_CAMPAIGN = "GET_ONE_CAMPAIGN";
