  import { SAVE_QUESTIONFROM2 } from '../actions/webinarActions/allActions';
  const initialSate = {
    questionform2Array: [],
  };
  const questionForm2Reducer = (state = initialSate, action) => {
    switch (action.type) {
      case SAVE_QUESTIONFROM2: {
        return {
          ...state,
          questionform2Array: action.response,
        };
      }
      default:
        return state;
    }
  };
  export default questionForm2Reducer;
  