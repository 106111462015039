  import { SAVE_QUESTIONFROM4 } from '../actions/webinarActions/allActions';
  
  const initialSate = {
    questionform4Array: [],
  };
  const questionForm4Reducer = (state = initialSate, action) => {
    switch (action.type) {
      case SAVE_QUESTIONFROM4: {
        return {
          ...state,
          questionform4Array: action.response,
        };
      }
      default:
        return state;
    }
  };
  export default questionForm4Reducer;
   