import React from 'react';
import PropTypes from 'prop-types';

import TabsBar from '../components/TabsBar';

class Card extends React.Component {
	render() {
		return this.props.noCard ? (
			<React.Fragment>{this.props.children}</React.Fragment>
		) : (
			<main id="custom-card-container" className="custom-card container-fluid">
				<div id="custom-card-body" className="custom-card row">
					<div className="col-12">
						<div
							className={`custom-card card ${this.props.classnames || ''} ${
								this.props.loading ? 'loading' : 'loaded'
							}`}
						>
							{this.props.tabs && this.props.tabHandler ? (
								<TabsBar
									tabsData={{
										handler: this.props.tabHandler,
										tabs: this.props.tabs,
									}}
									handleBackbutton={this.props.handleBackbutton || false}
								/>
							) : (
								''
							)}
							<div id="custom-card-content" className="card-body">
								{this.props.children}
							</div>
						</div>
						<img
							src="/assets/images/infinity-spinner.gif"
							className={`infinity-spinner ${
								this.props.loading ? 'loading' : 'loaded'
							}`}
							alt="loading spinner"
						/>
					</div>
				</div>
			</main>
		);
	}
}

Card.propTypes = {
	classNames: PropTypes.string,
	loading: PropTypes.bool,
	noCard: PropTypes.bool,
	tabs: PropTypes.array,
	tabHandler: PropTypes.func,
};

export default Card;
