import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Auth0 from 'auth0-web';
import { connect } from 'react-redux';

import { getUser, logActivity, setDemoFlag, registerUser } from '../ducks/GlobalReducer';

import LoginCard from './LoginCard';

import axios from 'axios';
import jwt from 'jwt-simple';

Auth0.configure({
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	audience: process.env.REACT_APP_AUTH0_AUDIENCE,
	clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
	redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
	responseType: 'token id_token',
	scope: 'openid get:products',
});

class AuthWrapper extends React.Component {
	state = {
		user: undefined,
		shouldFetchUser: false,
	};

	static getDerivedStateFromProps(props, state) {
		// declare object that will be returned at the end of this method
		let returned = {};

		// check to see if the user is on a demo account. if they are, set the header flag
		if (props.organization.package_id === 1) {
			// check to see if we need to set the demo flag
			if (!props.showDemoFlag) {
				props.setDemoFlag(true);
			}
		} else {
			// check to see if we need to unset the demo flag
			if (props.showDemoFlag) {
				props.setDemoFlag(false);
			}
		}

		if (props.shouldFetchUser) {
			returned.shouldFetchUser = true;
		}

		// if we don't have a user object on state, get it from props
		if (!state.user && props.user) {
			let jwtUser = jwt.encode(props.user, process.env.REACT_APP_JWT_SECRET);
			axios.defaults.headers.common['authorization'] = `Bearer ${jwtUser}`;

			returned.user = props.user;
		}

		// if something has updated in this method, return the updated. otherwise return null
		if (Object.keys(returned).length > 0) {
			return returned;
		} else {
			return null;
		}
	}

	componentDidMount() {
		Auth0.subscribe(async signedIn => {
			if (signedIn) {
				return this.setState({ signedIn });
			}

			const ssoStatus = await Auth0.silentAuth(
				'b2c-sso',
				process.env.REACT_APP_AUTH0_AUDIENCE,
				'openid get:products profile user_metadata app_metadata',
			);

			this.setState({
				signedIn: ssoStatus,
			});
		});
	}
	checkAuthentication = () => {
		const { pathname } = this.props.location;
		// check from auth0 if user is authenticated
		const authenticated = Auth0.isAuthenticated();

		if (pathname !== '/') {
			var result = true;
		}
		if (authenticated && pathname === '/callback') {
			return this.props.history.push('/');
		}
		// set user to redux store if not exists
		if (authenticated && !this.props.user) {
			// get profile
			let user = {};
			let existingSession = sessionStorage.getItem('proxyLogin');
			if (existingSession) {
				user = jwt.decode(existingSession, process.env.REACT_APP_JWT_SECRET);
			} else {
				user = Auth0.getProfile();
			}
			let user_metadata = user[process.env.REACT_APP_USER_METADATA];
			if (user_metadata && user_metadata?.isNewUser) {
				axios
					.post(`/api/v1/hooks/pre-register`, {
						email: user?.name,
						first_name: user_metadata?.first_name,
						last_name: user_metadata?.last_name,
						organization_name: user?.name,
					})
					.then(resp => {
						if (!this.props.fetchingUser) {
							this.props.getUser(user);
						}
					})
					.catch(err => {
						if (!this.props.fetchingUser) {
							this.props.getUser(user);
						}
					});
			} else if (!this.props.fetchingUser) {
				if (!user.name) {
					user.name = user.email;
				}
				this.props.getUser(user);
			}
			// for the production DB if a user is in auth0 but not in the db log them out
			if (this.props.logOutUser && authenticated) {
				this.logout();
			}
		}
		return { authenticated, result };
	};

	login = () => {
		Auth0.signIn();
	};

	logout = () => {
		if (!this.props.logOutUser) {
			let user_id = this.props.user.id;
			this.props.logActivity(user_id, 'Logged Out');
		}

		Auth0.signOut({
			returnTo: process.env.REACT_APP_AUTH0_SIGN_OUT_REDIRECT_URI,
			clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
		});
	};
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.user !== this.props.user && this.props.user !== '') {
			if (this.props.user.tenant_id) {
				sessionStorage.setItem(
					'tenant_id',
					jwt.encode(this.props.user.tenant_id, process.env.REACT_APP_JWT_SECRET) || '',
				);
			}
		}
	}

	render() {
		const { authenticated, result } = this.checkAuthentication();
		var props = null;
		if (result) {
			props = {
				authenticated: authenticated,
				login: this.login,
				logout: this.logout,
				...this.props,
				authLoader: true,
			};
		} else {
			props = {
				authenticated: authenticated,
				login: this.login,
				logout: this.logout,
				...this.props,
				authLoader: false,
			};
		}

		return this.props.user ? this.props.render(props) : <LoginCard {...props} />;
	}
}

class AuthCallback extends React.Component {
	componentDidMount() {
		Auth0.handleAuthCallback();
	}

	render() {
		return <div>Loading Profile...</div>;
	}
}

// ========== REDUX ========== //

function mapStateToProps(state) {
	return {
		user: state.GlobalReducer.user,
		fetchingUser: state.GlobalReducer.fetchingUser,
		registeringUser: state.GlobalReducer.registeringUser,
		shouldFetchUser: state.GlobalReducer.shouldFetchUser,
		organization: state.GlobalReducer.organization,
		package_info: state.GlobalReducer.package_info,
		logOutUser: state.GlobalReducer.logOutUser,
		showDemoFlag: state.GlobalReducer.showDemoFlag,
	};
}

const mapDispatchToProps = {
	getUser,
	logActivity,
	setDemoFlag,
	registerUser,
};

// ========== EXPORTS ========== //

export { AuthCallback };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthWrapper));
