import React from 'react';
import propTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

class NavBar extends React.Component {
	state = {
		clicked: null,
	};

	handleClick = route_string => this.setState({ clicked: route_string || null });

	render() {
		// undefined variable for use in activeRoute
		let mapNavigationRoutes = undefined;

		// determine the active route

		let activeRoute;
		if (this.props.applications && this.props.applications.length) {
			activeRoute = this.props.applications.filter(e => {
				let pathname = this.props.location.pathname;
				pathname =
					pathname && ~pathname.indexOf('/')
						? this.props.location.pathname.split('/')[1]
						: '';
				pathname = '/' + pathname;

				// if the application has no routes, do nothing
				if (!e.nav_routes) {
					return null;
				}

				if (e.nav_routes.baseurl.includes(pathname)) {
					return e;
				}

				return null;
			});
			// if activeRoute is valid (and we have user data from database)
			if (activeRoute.length) {
				// if the application doesn't have any routes, don't attempt to map over them
				if (!activeRoute[0].nav_routes) {
					return null;
				}

				// map over activeRoute and display navigation information
				mapNavigationRoutes = activeRoute[0].nav_routes.routes.map((e, i) => {
					let baseurl = activeRoute[0].nav_routes.baseurl;
					let route = '';
					let route_string = '';
					// Logic specific to handling analytic routes status'
					if (baseurl === '/analytics' && this.props.site.site) {
						e.active = true;
					} else if (
						baseurl === '/analytics' &&
						!this.props.site.site &&
						e.route !== '/notifications' &&
						e.title !== 'Sites' &&
						e.title !== 'Create'
					) {
						e.active = false;
					}
					// the route_string should be set according to whether it's displayed in an iframe or not
					if (e.iframe && baseurl === '/analytics') {
						route_string =
							baseurl +
							e.route.replace(/([:])\w+/g, this.props.site.site) +
							'?iframe=' +
							e.iframe.replace(/([:])\w+/g, this.props.site.site);
						if (this.props.site.site !== null) {
							e.active = true;
						}
					} else if (e.iframe && baseurl !== '/analytics') {
						route_string = baseurl + e.route + '?iframe=' + e.iframe;
					} else {
						route_string = baseurl + e.route;
					}
					if (e.auth) {
						route_string += route_string.includes('iframe')
							? '&auth=' + e.auth
							: '?auth=' + e.auth;
					}
					// If site active, map through and activate all subroutes
					// if route is hidden, don't return anything
					if (e.hidden) {
						return '';
					}

					// check to see if the route is currently being viewed
					let isCurrent = false;

					if (
						this.props.history.location.pathname.includes(e.route) ||
						this.props.history.location.pathname.includes(
							e.route.replace(/([:])\w+/g, this.props.site.site),
						)
					) {
						isCurrent = true;
					}

					// stop highlighting dashboard if the user is not on that page
					if (
						e.route === '/' &&
						this.props.history.location.pathname !== baseurl &&
						this.props.history.location.pathname !== baseurl + e.route
					) {
						isCurrent = false;
					}

					// if route is active, it should be clickable
					if (e.active) {
						// if route has subroutes, it should show them on click
						if (e.subroutes) {
							// route is currently open
							if (this.state.clicked === route_string) {
								// mapped subroutes
								const mapSubroutes = e.subroutes.map((subroute, subindex) => {
									route_string = baseurl + e.route + subroute.route;

									isCurrent = false;
									// Enable all subroutes of application
									if (e.subroutes && this.props.site.site !== null) {
										subroute.active = true;
										if (subroute.iframe) {
											route_string =
												baseurl +
												subroute.route.replace(
													/([:])\w+/g,
													this.props.site.site,
												) +
												'?iframe=' +
												subroute.iframe.replace(
													/([:])\w+/g,
													this.props.site.site,
												);
										} else {
											route_string = baseurl + subroute.route;
										}
										if (subroute.auth) {
											route_string += route_string.includes('iframe')
												? '&auth=' + subroute.auth
												: '?auth=' + subroute.auth;
										}
									} else {
										subroute.active = false;
									}
									if (
										this.props.history.location.pathname.includes(
											subroute.route,
										) ||
										this.props.history.location.pathname.includes(
											subroute.route.replace(
												/([:])\w+/g,
												this.props.site.site,
											),
										)
									) {
										isCurrent = true;
									}

									// stop highlighting dashboard if the user is not on that page
									if (
										subroute.route === '/' &&
										this.props.history.location.pathname !== baseurl &&
										this.props.history.location.pathname !==
											baseurl + subroute.route
									) {
										isCurrent = false;
									}

									// disable route if not active
									if (!subroute.active) {
										return (
											<li
												key={subindex}
												className="side-nav-item btn disabled py-1 text-left"
											>
												<div className="side-nav-link p-0 m-0">
													<span className="text-muted pl-2">
														{subroute.title}
													</span>
												</div>
											</li>
										);
									}

									return (
										<li
											key={subindex}
											className="side-nav-item btn py-1 text-left"
										>
											<Link
												to={route_string}
												className="side-nav-link p-0 m-0 d-flex justify-content-left align-items-center"
											>
												{isCurrent ? (
													<React.Fragment>
														<i className="custom-navbar-icon mdi p-1x mdi-brightness-1 mdi-1x d-block mr-0 text-light" />
														<span className="pl-0">
															{' '}
															{subroute.title}{' '}
														</span>
													</React.Fragment>
												) : (
													<span className="pl-2"> {subroute.title} </span>
												)}
											</Link>
										</li>
									);
								});
								route = (
									<li key={i} className="side-nav-item btn py-1 text-left">
										<div className="side-nav-link p-0 m-0 d-flex justify-content-center align-items-left flex-column">
											<span className="pl-2"> {e.title} </span>
											<hr className="m-0 p-0 w-75" />
											<ul className="d-flex flex-column">{mapSubroutes}</ul>
											<hr className="m-0 p-0 w-75" />
										</div>
									</li>
								);
							} else {
								// route is not currently open
								route = (
									<li key={i} className="side-nav-item btn py-1 text-left">
										<div
											onClick={() => this.handleClick(route_string)}
											className="side-nav-link p-0 m-0 d-flex justify-content-left align-items-center"
										>
											<span className="pl-2"> {e.title} </span>
										</div>
									</li>
								);
							}
						} else {
							// route has no subroutes
							route = (
								<li key={i} className="side-nav-item btn py-1 text-left">
									<Link
										//this one  to change
										to={route_string}
										onClick={() => this.handleClick(route_string)}
										className="side-nav-link p-0 m-0 d-flex justify-content-left align-items-center"
									>
										{isCurrent ? (
											<React.Fragment>
												<i className="custom-navbar-icon mdi p-1x mdi-brightness-1 mdi-1x d-block mr-0 text-light" />
												<span className="pl-0"> {e.title} </span>
											</React.Fragment>
										) : (
											<span className="pl-2"> {e.title} </span>
										)}
									</Link>
								</li>
							);
						}
					} else {
						// if route is inactive, it should be visible, but not clickable
						route = (
							<li key={i} className="side-nav-item btn disabled py-1 text-left">
								<div className="side-nav-link p-0 m-0">
									<span className="text-muted pl-2"> {e.title} </span>
								</div>
							</li>
						);
					}

					return (
						<React.Fragment key={i}>
							{/* Category */}
							<li className="side-nav-title side-nav-item custom-nav-item">
								{e.category}
							</li>

							{/* Routes */}
							{route}
						</React.Fragment>
					);
				});
			}
		}
		return (
			<div className="custom-navbar" id="custom-navbar">
				<div className="slimscroll-menu">
					{/* AXIS LOGO */}
					<span className="pt-2 d-flex flex-column align-items-center justify-content-start">
						<img src="assets/images/logo.png" alt="" height="30" />
						<div className="text p-0 my-1 text-light text-center">
							{activeRoute && activeRoute[0] && activeRoute[0].name}
						</div>
						<hr className="w-75 text-light m-0 p-0" />
					</span>
					{/* <Modal
						visible={this.state.visible}
						width="500"
						height="300"
						effect="fadeInUp"
						onClickAway={() => this.closeModal()}
					>
						{' '}
						hey{' '}
					</Modal> */}
					{/* ROUTES */}
					<ul className="metismenu side-nav d-flex flex-column">{mapNavigationRoutes}</ul>

					<div></div>
					{/* METADATA */}
					<div className="clearfix" />

					{/* DFY LOGO */}
					<div id="navbar-dfy-logo" className="container">
						<img
							src="https://s3.us-east-2.amazonaws.com/axis-sidebar/dfy-logo.png"
							alt="Done For You"
							height="25"
						/>
					</div>
				</div>
			</div>
		);
	}
}

NavBar.propTypes = {
	routes: propTypes.arrayOf(propTypes.object),
};

function mapStateToProps(state) {
	return {
		site: state.GlobalReducer.analytics,
		applications: state.GlobalReducer.applications,
	};
}

export default withRouter(connect(mapStateToProps)(NavBar));
